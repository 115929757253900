.activeBorder {
  /*border: 4px solid #ffc800;*/
}
.tab {
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.14 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  text-transform: initial !important;
}

.active-tab {
  color: #000 !important;
}

.inactive-tab {
  color: #646464 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.Mui-checked {
  color: #000 !important;
}

.Mui-focused {
  color: #000 !important;
}

.MuiOutlinedInput-notchedOutline {
  /*border: none !important;*/
}
#email {
  z-index: 1;
}
.custom-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #fa783c 94%, #0000) top/8px 8px
      no-repeat,
    conic-gradient(#fa783c 30%, #ffc800);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}
.custom-loader-backdrop {
  z-index: 222;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}


.changePlanBtn.Mui-disabled, .voicePlanBtn.Mui-disabled, .planOptionsCancelBtn.Mui-disabled {
  background: #fff1bf !important;
  color: #4d4d4d !important;
}
