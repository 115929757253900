$font-family-Poppins: Poppins, Helvetica, sans-serif !important;
//$font-family-PoppinsSemiBold: PoppinsSemiBold, Helvetica, sans-serif !important;
$font-family-PoppinsSemiBold: Poppins !important;
$font-family-PoppinsBold: PoppinsBold, Helvetica, sans-serif !important;
$font-family-Inter: Inter, Helvetica, sans-serif !important;
$font-family-InterBold: InterBold, Helvetica, sans-serif !important;
$font-family-InterSemiBold: InterSemiBold, Helvetica, sans-serif !important;
$font-family-InterMedium: InterMedium, Helvetica, sans-serif !important;
$font-family-GraphikRegular: GraphikRegular, Helvetica, sans-serif !important;
$font-family-GraphikBold: GraphikBold, Helvetica, sans-serif !important;
$bs-strong-black-100: #000000;

.wideContainer {
  button.MuiButtonBase-root.MuiButton-root {
    height: 48px;
    width: 240px;
    box-shadow: none;
    font-family: $font-family-Poppins;
    letter-spacing: normal;
    @media screen and (max-width:767.9px) {
      width: 100%;
    }
    span {
      font-family: $font-family-Poppins;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: center;
      color: $bs-strong-black-100;
    }
  }
  .logoutBtnDesk {
    margin-top: 51.5px !important;
  }
  .navListBottom {
    .MuiListItemIcon-root {
      margin-right: 2px !important;
    }
  }
  .MuiDivider-root.MuiDivider-fullWidth {
    //   border:1px solid #7f7f7f;
    border-color: $bs-strong-black-100;
    border-width: 0px !important;
    opacity: unset;
    //  margin: 55px 44px;
    height: 1px;
  }
.MuiDrawer-root {
  .navListBottom {
    .logout-btn {
      width: 100%;
      padding-left: 44px;
      justify-content: flex-start;
      gap: 16px;
      height: 35px;
      text-transform: none;
      border-radius: 0;
      .logout-text {
        font-weight: 400;
        font-family: $font-family-Inter;
        color: $bs-strong-black-100;
      }
      &.Mui-focusVisible {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
    &:hover {
      background: #ffc800;
    }
  }
}

  .MuiDrawer-docked.drawerActive {
    width: 272px;
    margin-left: 0;
  }
  .menuList {
    .MuiDivider-root.MuiDivider-fullWidth {
      margin: 56.5px 44px;
    }
  }
  .MuiDrawer-root.MuiDrawer-docked {
    overflow: hidden;
    background-color: #fff;
    .MuiListItem-root.MuiListItem-gutters {
      height: 35px;
      margin-top: 24px;

      &:first-child {
        margin-top: 0;
      }

      &:hover {
        height: 35px;
      }
    }

    .MuiListItemIcon-root {
      margin-right: 8px;
    }
  }

  .MuiDrawer-docked.drawerInactive {
    width: 80px;
    margin-left: 0;
  }

  .MuiDrawer-docked.drawerInactive > div {
    width: 78px;
    z-index: auto;

    .drawer-header-sticky {
      justify-content: flex-start !important;
    }

    .userNameSideBar,
    .logout-btn,
    .MuiList-padding .MuiListItem-gutters .MuiListItemButton-root {
      padding-left: 25px;
    }

    .userNameSideBar {
      margin-bottom: 0;
    }
    .logout-text,
    .wrapperdiv {
      display: none;
    }

    .MuiDivider-root.MuiDivider-fullWidth {
      margin: 48px 20px;
    }
  }

  .MuiDrawer-docked {
    .MuiDrawer-paper {
      position: relative;
      overflow: hidden;
    }
  }

  .MuiDrawer-docked.drawerActive > div {
    width: 270px;
    z-index: auto;
  }

  .MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
    top: 46px;
  }

  .MuiDrawer-paperAnchorLeft {
    .MuiList-padding {
      .MuiListItem-gutters {
        &:hover {
          background: #ffc800;
        }

        .MuiListItemButton-root {
          padding: 0px 0px 0px 44px;
          height: 34px;
          min-height: 34px;
          &:hover {
            background: none;
          }

          .MuiListItemText-root {
            margin: 0;
            //height: 21px;
            span {
              font-family: $font-family-Inter;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              color: $bs-strong-black-100;
              padding-top: 0px;
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .dashboardContainer {
    // @media screen and (min-width: 1439.9px) {
    //   max-width: 1024px;
    //   margin: 0 auto;
    // }
    .slideBtn {
      z-index: 2;
      height: 43px;
      position: sticky;
      width: 0;
      top: 400px;
      cursor: pointer;
      padding: 0;
    }
  }

  .pageTitle {
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 40px;
    padding: 0;
    font-family: $font-family-Poppins;
    line-height: 31px; 
    color: $bs-strong-black-100;
  }

  @media screen and (max-width: 767.9px) {
    .pageTitle {
      margin-bottom: 32px;
      padding-left: 16px;
    }
  }

  .MuiList-root {
    margin: 0;
    padding: 0;
  }

  .userNameSideBar {
    text-align: left;
    margin: 0;
    padding-left: 44px;

    p {
      font-family: $font-family-PoppinsSemiBold;
      font-size: 19px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 27px;
      letter-spacing: normal;
      color: $bs-strong-black-100;
      white-space: pre-line;
      text-transform: capitalize;
    }
  }
  .accountNumberSideBar {
    text-align: left;
    margin: 0;
    padding-left: 44px;

    p,label {
      font-family: $font-family-PoppinsSemiBold;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      // padding-top: 16px;
      padding-bottom: 16px;
      font-style: normal;
      line-height: 21px;
      letter-spacing: normal;
      color: $bs-strong-black-100;
    }
  }

  .addressSideBar {
    text-align: left;
    margin: 0;
    padding-left: 44px;

    p {
      font-family: Inter !important;
      font-size: 12px;
      font-weight: 400;
      font-stretch: normal;
      /* padding-top: 5%; */
      margin-top: 0;
      font-style: normal;
      line-height: 16px;
      letter-spacing: normal;
      color: $bs-strong-black-100;
    }
    &:first-of-type {
      p {
        margin-top: 0;
      }
    }
  }

  .main-container {
    .BackButton {
        background: none;
        border: 0px;
        text-decoration: underline;
        margin-bottom: 13px;
        padding-left: 0px;
        img {
          padding-right: 16px;
        }
      }
    .MuiBox-root,
    .MuiListItem-root.MuiListItem-gutters {
      margin: 0;
      padding: 0;
    }

    .paymet-detail-box {
      button {
        padding: 0;
        margin: 0;
        height: 48px;      
        width: 240px;
        box-shadow: none;
        font-family: $font-family-Poppins;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 16px;
        letter-spacing: normal;
        text-align: center;
        color: $bs-strong-black-100;

        &:hover {
          background: $bs-strong-black-100;
          color: #ffffff;
        }
      }
    }
  }
  .paymentspanel {
    div.MuiBox-root.addPaymentMethodBox {
      padding: 0px !important;
      background: none !important;
      margin: 40px 0px;
      @media screen and (max-width: 767.9px) {
        margin: 32px 0px 32px 16px;
      }
    }
  }

  .paymentspanel > div.MuiBox-root > div.MuiBox-root {
    padding: 48px 24px 48px 24px;
    background: #ffffff;
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 1023.9px) and (min-width: 768px) {
    // .paymentspanel > div.MuiBox-root > div.MuiBox-root {
    //   padding: 48px 16px 48px 16px;
    //   margin-top: 32px;
    // }
    .accountSetting .rightDiv, 
    .wideContainer .main-container .MuiBox-root .accountSetting .phonePrimary.rightDiv, 
    .accountSetting .emailSecondary {
      margin-top: 24px;
    }
  }

  @media screen and (max-width: 767.9px) {
    .paymentspanel > div.MuiBox-root > div.MuiBox-root {
      padding: 48px 16px 48px 16px;
      margin-top: 32px;
    }
    
    .accountSetting .rightDiv, 
    .wideContainer .main-container .MuiBox-root .accountSetting .phonePrimary.rightDiv, 
    .accountSetting .emailSecondary {
      margin-top: 24px;
    }
  }

  /*.paymentspanel > div.MuiBox-root div.MuiBox-root > div{
  margin-top: 40px;
 }*/
  .appointmentHistory.MuiBox-root {
    margin-top: 40px;
  }

  .upcominingAppointment.MuiBox-root,
  .appointmentHistory.MuiBox-root {
    padding: 32px 16px;
    @media screen and (min-width:768px) {
      padding: 48px 24px;
    }
  }

  .reminderPopup.MuiBox-root {
    // display: flex;
    // align-items: center;
    margin-bottom: 40px;
    min-height: 84px;
    background: $bs-strong-black-100;
    padding: 16px 20px 19px 32px;
    justify-content: space-between;
    img {
      float: left;
    }
    p {
      margin: 0px 48px 0px 24px;
      font-family: $font-family-Inter;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #ffffff;
      display: inline-block;
      width: calc(100% - 145px);
    }
    img.cursor-pointer {
      float: right;
    }
  }

  .helpSupport > div.MuiBox-root {
    padding: 48px 24px;
    margin-top: 40px;
    @media screen and (max-width:767.9px) {
      padding: 48px 16px;
      margin-top: 32px;
    }
    &:first-child {
      margin-top: 0;
    }

    p {
      margin: 0px 0px 0px 0px;
      padding: 0;
    }

    hr {
      // height: 2px;
      border: solid 1px $bs-strong-black-100;
      margin-bottom: 29px;
      opacity: unset;
      // margin-top: 10.5px;
    }
  }

  .containerHeader {
    .title {
      font-family: $font-family-Poppins;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 36px;
      letter-spacing: normal;
      color: $bs-strong-black-100;
      @media screen and (max-width: 767.9px) {
        margin-left: 16px;
        padding-right: 16px;
        word-wrap: break-word;
      }
    }

    .subtitle {
      font-family: $font-family-Inter;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $bs-strong-black-100;
      margin-top: 12px;
      margin-bottom: 0;
      @media screen and (max-width: 767.9px) {
        margin-left: 16px;
      }
    }

    .MuiTabs-root {
      margin-top: 48px;
      margin-bottom: 40px;
      @media screen and (max-width: 767.9px) {
        margin: 32px 0;
      }
      .MuiTabs-scroller {
        border-bottom: solid 1px #bababa;
      }

      .MuiTabs-flexContainer {
        button.MuiButtonBase-root.MuiTab-root {
          font-family: $font-family-Poppins;
          font-size: 14px !important;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 21px!important;
          letter-spacing: normal;
          color: #000;
          @media screen and (max-width:767.9px) {
            font-size: 12px !important;
            line-height: 16px!important;           
          }
        }
      }

      .MuiTabs-indicator {
        height: 4px;
      }
    }
  }

  /*.containerHeader {
 .activeBorder{
    padding: 32px 20px 40px 20px;
 }
 hr{
    margin: 5px 0px 40px 0px;
 }
 }*/
  .payment-box {
    .sectionTitle {
      font-family: $font-family-PoppinsSemiBold;
      font-size: 19px;
      font-weight: 600;       
      line-height: 27px;
      letter-spacing: normal;
      color: $bs-strong-black-100;
      margin: 0;
    }  
    hr {
      margin: 16px 0 32px 0;
    }

    .paymet-detail-box > p {
      font-family: $font-family-PoppinsSemiBold;
      font-size: 28px;
      font-weight: 600;     
      font-style: normal;
      line-height: 36px;
      letter-spacing: normal;
      color: $bs-strong-black-100;
      margin-bottom: 24px;
    }

    table {
      margin-bottom: 32px;
    }

    thead.headerBg {
      border-top: solid 0.5px $bs-strong-black-100;

      tr {
        th {
          border-bottom: solid 0.5px $bs-strong-black-100;
          font-family: $font-family-Inter;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: $bs-strong-black-100;
          width: 50%;
          padding: 5px 0;
          span {
            margin: 0;
          }
        }
      }
    }

    tbody.tbody {
      tr {
        td {
          font-family: $font-family-PoppinsSemiBold;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $bs-strong-black-100;
          width: 50%;
          padding: 10px 0;

          &:last-child {
            font-family: $font-family-Inter;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: $bs-strong-black-100;
          }
        }
      }
    }
  }
  hr {
    margin-top: 16px;
    margin-bottom: 1.5px;
  }
    .service-box {
      .sectionTitle {
        font-family: $font-family-PoppinsSemiBold;
        font-size: 19px;
        font-weight: 600;       
        line-height: 27px;
        letter-spacing: normal;
        color: $bs-strong-black-100;
        margin: 0;
      }  
    }

  .service-box {
    hr {
      margin: 16px 0 32px 0;
    }

    .buttonText {
       background: none;
       color: #000;
       border: 0px;
      text-decoration: underline;
    }
    .serviceDetailsSub {
      font-family: $font-family-PoppinsSemiBold;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 36px;
      letter-spacing: normal;
      color: $bs-strong-black-100;
      margin-bottom: 32px;
      margin-top: 0;
    }

    .serviceDetailsList {
      margin-top: 24px;
      li {
        line-height: 21px;
        div.MuiListItemIcon-root {
          min-width: 21px !important;
        }
        .MuiListItemText-root {
          margin: 0;
        }

        span {
          font-family: $font-family-Inter;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $bs-strong-black-100;
          margin-left: 8px;
        }
        div.checkMarkIcon {
          width: 12px;
          height: 12px;
          min-width: 12px !important;
          background: url("../images/check-mark.svg") no-repeat left;
          background-size: contain;
        }
        // svg {
        //   width: 13px;
        //   height: 11px;
        // }
      }
    }
  }

  .quickLinks {
    padding: 48px 24px;

    p,h2 {
      font-family: $font-family-PoppinsSemiBold;
      font-size: 19px;
      font-weight: 600;     
      font-style: normal;
      line-height: 27px;
      letter-spacing: normal;
      color: $bs-strong-black-100;
      margin: 0;
    }

    hr {
      margin: 16px 0 32px 0;
    }

    .quickListMain {
      display: flex;
      gap: 40px;

      .quickList {
       
        width: 50%;
        gap: 0;

        &:last-child {
          margin-left: 20px;
        }

        a,
        span {
          font-family: $font-family-Inter;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 21px;
          letter-spacing: normal;
          color: $bs-strong-black-100;
          margin-bottom: 16px;
        
        }
        @media screen and (min-width:1024px) {
          a:last-child {
            margin-bottom: 0;
          } 
        }                    
      }
    }
  }

  @media screen and (max-width: 1023.9px) and (min-width: 768px) {
    .main-container {
      .containerHeader {
        .payment-and-service-box {
          flex-direction: column;

          .payment-box,
          .service-box {
            width: 100%;
          }
        }
      }

      .quickListMain {
        flex-direction: column;
        gap: 0;

        .quickList {
          width: 100%;

          &:last-child {
            margin-top: 0;
            margin-left: 0;
            a:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .supportTools .cardParent {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media screen and (max-width: 767.9px) {
    .quickLinks .quickListMain {
      flex-direction: column;
      gap: 0 !important;

      .quickList {
        width: 100%;

        &:last-child {
          margin-top: 0;
          margin-left: 0 !important;
          a:last-child {
            margin-bottom: 0;
          }
        }    
      }
    }
  }
  .myBills {
    hr {
      margin: 16px 0px 40px 0px !important;
    }
  }
  .accountSetting {
    hr {
      margin: 16px 0px 0px 0px !important;
    }
  }

  .accountSetting,
  .myBills {
    .MuiBox-root {
      .MuiTabs-root {
        border-bottom: 1px solid #646464;
        margin-bottom: 40px;
        padding: 0;
      }
      @media screen and (max-width: 767.9px) {
        .MuiTabs-root {
          padding-left: 16px;
          margin-bottom: 32px;
        }
      }
    }

    /* button.MuiButtonBase-root {
      // font-family:$font-family-InterSemiBold!important;
      font-size: 14px !important;
      font-weight: 600 !important;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $bs-strong-black-100;
      padding: 0;

      &:last-child {
        margin-left: 85px;

        @media screen and (max-width: 767.9px) {
          //margin-left: 0;
        }
      }
      &:hover {
        // background: $bs-strong-black-100;
        // color: #000000 !important;
      }
    }*/

    span.MuiTabs-indicatorSpan {
      max-width: 100%;
    }

    .title {
      font-family: $font-family-PoppinsSemiBold;
      font-size: 19px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 27px;
      letter-spacing: normal;
      color: $bs-strong-black-100;
      margin-bottom: 16px;
    }

    .name,
    .phone,
    .address {
      p {
        &:first-child {
          font-family: $font-family-Inter;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 16px;
          letter-spacing: normal;
          color: $bs-strong-black-100;
          margin-bottom: 8px;
        }

        &:last-child {
          font-family: $font-family-PoppinsSemiBold;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 24px;
          letter-spacing: normal;
          color: $bs-strong-black-100;
        }
      }
    }

    .email,
    .phone {
      p {
        &:first-child {
          font-family: $font-family-Inter;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 16px;
          letter-spacing: normal;
          color: $bs-strong-black-100;
          margin-bottom: 8px;
        }
      }

      .emailPrimary,
      .phonePrimary,
      .emailSecondary {
        P {
          &:first-child {
            font-family: $font-family-PoppinsSemiBold;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 24px;
            letter-spacing: normal;
            color: $bs-strong-black-100;
            margin: 0px 0px 8px 0px;
            // height: 24px;
          }

          &:last-child {
            font-family: $font-family-Inter;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 16px;
            letter-spacing: normal;
            color: $bs-strong-black-100;
          }
        }
      }
    }

    .phone,
    .email {
      margin-top: 24px;
    }

    .address {
      margin-top: 24px;
      margin-bottom: 24px;

      button {
        @media screen and (max-width: 767.9px) {
          width: 100%;
        }

        &:last-child {
          margin-left: 0px;
        }
      }
    }

    .required {
      margin-bottom: 12.8px;
    }

    .required,
    .contactSupport {
      font-family: $font-family-Inter;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 16px;
      letter-spacing: normal;
      color: $bs-strong-black-100;
    }

    .addressInfo {
      margin-top: 24px;

      p {
        font-size: 19px;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .mailingAddressForm .MuiFormLabel-root.MuiInputLabel-root {
      font-weight: 400;
      .addressTwo {
        margin-top: 8px;
      }
    }

    .addrFrmDivider {
      // margin-bottom: 0.9rem !important;
      margin-bottom: 24px!important;
    }

    .communications {
      .title {
        font-family: $font-family-PoppinsSemiBold;
        font-size: 19px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        color: $bs-strong-black-100;
      }

      hr {
        margin: 10.5px 0px 32.5px 0px;
      }

      p.type {
        font-family: $font-family-PoppinsSemiBold;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $bs-strong-black-100;
        margin: 0px 0px 12px 0px;
      }

      .combobox > div {
        border-radius: unset;
        width: 436px;
        height: 18px;
        border: 1px solid $bs-strong-black-100;
        font-family: $font-family-Inter;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $bs-strong-black-100;

        @media screen and (max-width: 767.9px) {
          width: 100%;
        }
      }

      .method {
        margin-top: 24px;
        margin-bottom: 12px;
      }

      .checkboxes {
        label {
          margin: 0px 75px 0px 0px;

          @media screen and (max-width: 767.9px) {
            margin: 0px 20px 0px 0px;
          }

          span {
            padding: 0;
          }

          p {
            margin-left: 12px;
            font-family: $font-family-Inter;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: $bs-strong-black-100;
          }
        }
      }
    }

    .passwordInfo {
      .formMain {
        flex-direction: column;
        display: flex;
        width: 460px;
        margin-bottom: 16px;
        margin-top: 24px;
        @media screen and (max-width: 767.9px) {
          width: 100%;
        }
      }

      .bottomSection {
        display: flex;
        flex-direction: column;

        button {
          &:hover {
            background: $bs-strong-black-100 !important;
            color: #ffffff !important;
          }

          padding: 0;
          margin: 0;
          width: 240px;
          height: 48px;
          border-radius: 24px;
          background-color: #ffc800;
          cursor: pointer;
          font-family: $font-family-Poppins;

          @media screen and (max-width: 767.9px) {
            width: 100%;
          }
        }

        p, a.clikhere {
          font-family: $font-family-Inter;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $bs-strong-black-100;
          margin-top: 16px;
        }
      }
    }

    .langPref {
      .title {
        font-family: $font-family-PoppinsSemiBold;
        font-size: 19px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        color: $bs-strong-black-100;
      }

      hr {
        margin: 10.5px 0px 32.5px 0px;
      }

      .bodyDiv {
        display: flex;
        align-items: flex-start;
      }

      .MuiInputBase-root {
        width: 436px;

        @media screen and (max-width: 767.9px) {
          width: 100%;
        }
      }

      .MuiSelect-select {
        width: 436px;
        height: 18px;
        border: 1px solid $bs-strong-black-100;
        font-family: $font-family-Inter;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $bs-strong-black-100;

        @media screen and (max-width: 767.9px) {
          width: 100%;
        }
      }

      .bottomDiv {
        margin-top: 40px;

        @media screen and (max-width: 767.9px) {
          display: inline-block;
        }

        button {
          margin: 0;
          padding: 0;

          &:last-child {
            margin-left: 20px;

            @media screen and (max-width: 767.9px) {
              margin-top: 20px;
              margin-left: 0;
            }
          }

          @media screen and (max-width: 767.9px) {
            width: 100%;
          }
        }
      }
    }

    .planDetails {
      .title {
        font-family: $font-family-PoppinsSemiBold;
        font-size: 19px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        color: $bs-strong-black-100;
      }

      hr {
        margin: 10.5px 0px 32.5px 0px;
      }

      .planDivforctl {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        .ctlPhoneno {
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          margin-left: 16px;
        }
      }

      .planDiv {
        .heading {
          font-family: $font-family-Inter;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: $bs-strong-black-100;
        }
        .cancelledService {
          color: $bs-strong-black-100;
          font-family: $font-family-Poppins;
          font-size: 19px;
          font-style: normal;
          font-weight: 600;
          line-height: 27px; /* 142.105% */
          margin-top: 40px;
          margin-bottom:40px;
          @media screen and (max-width: 767.9px) {
            margin-top: 32px;
            margin-bottom:32px;
          }
        }

        .planName {
          font-family: $font-family-PoppinsSemiBold;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $bs-strong-black-100;
          margin-top: 4px;
        }

        .planDetails {
          font-family: $font-family-Inter;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #646464;
          margin-top: 4px;
        }
      }
    }

    .chatDiv {
      .title {
        font-family: $font-family-PoppinsSemiBold;
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
        color: $bs-strong-black-100;
      }

      p.subtitle {
        font-family: $font-family-Inter;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: $bs-strong-black-100;
        margin: 24px 0px 40px 0px;
      }

      button {
        margin: 0 !important;
        padding: 0;
      }
    }
  }

  .main-container.drawerActive {
    @media screen and (max-width: 1023.9px) and (min-width: 768px) {
      .accountSetting .communications .combobox > div,
      .MuiSelect-select,
      .MuiInputBase-root,
      .passwordInfo .formMain {
        width: 100%;
      }
    }
  }

  .myBills {
    hr {
      margin: 0;
    }
    .currentStatement {
      > hr {
        margin: 16px 0px 32px 0px !important;
      }

      .statementDiv > div {
        margin: 0px 0 10px 0px;
        padding: 0;
        max-width: fit-content;       
      }

      .statementDiv {
        margin: 0;       
        p:first-child {
          font-family: $font-family-Inter;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: $bs-strong-black-100;
        }

        p:last-child, label:last-child {
          font-family: $font-family-PoppinsSemiBold;
          font-size: 23px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 31px;
          letter-spacing: normal;
          color: $bs-strong-black-100;
          margin-top: 16px;
        }
        .upcoming-charges-inside-lables {
          font-family: $font-family-Inter;
          color: $bs-strong-black-100;
        }
      }
      @media screen and (max-width:1023.9px) {
        .page-cta {
          flex-direction: column-reverse;
          margin-top: 28px !important;    
          button:first-child {
            margin-top: 18px !important;
          }
        }
      }      
      .MuiAccordionSummary-content {
        margin: 0;
        &.Mui-expanded {
          margin:0;
        }
      }
      .MuiAccordionSummary-root {
        padding: 0;
      } 
     .MuiAccordion-root.Mui-expanded {
        margin: 28px 0 0;
      }   
      .MuiAccordionSummary-root.Mui-expanded {
        min-height: 48px;
      }
    }
  }

  .paymentHistory {
    .tableContainer {
      table {
        border-spacing: 0;
        thead {
          tr {
            th {
              font-family: $font-family-Inter;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 16px;
              letter-spacing: normal;
              color: $bs-strong-black-100;
              margin: 0px;
              padding: 32px 0 40px 0;
              max-width: 1vw;
            }
            th:last-child {
              max-width: 45px;
            }
          }
        }

        tbody {
          // tr:first-child > td {
          //   padding: 24px 0px 24px 0px;
          // }

          tr {
            border-bottom-style: none;

            td {
              font-family: $font-family-InterMedium;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 21px;
              letter-spacing: normal;
              color: $bs-strong-black-100;
              padding: 0px 0px 0px 0px;
              max-width: 1vw;
                             
              table {
                thead {
                  tr {
                    th {
                      color: #4D4D4D;
                      padding: 16px 0px 0 0px;
                      text-align: left;
                    }
                  }
                }

                tbody {
                  tr {
                    td.MuiTableCell-root {
                      text-align: left;
                      font-family: $font-family-Inter;
                      font-size: 14px;
                      font-weight: 400;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 21px;
                      letter-spacing: normal;
                      color: #4D4D4D;
                      padding: 0px !important;
                    }
                    td.space-below {
                      padding: 16px 0 24px 0 !important;
                    }
                  }
                }
              }
            }
          }
          tr > td:last-child {
            max-width: 0.2vw;
          }
        }
      }

      table.inner-table {
        tr > td:last-child {
          max-width: 1vw;
        }
      }

      .top-border > td {
        border-top: 1px solid #7f7f7f;
      }
      .bottom-border > td {
        border-bottom: 1px solid #7f7f7f;
      }
      .table-divider {
        color: #7f7f7f;
      }
      .margin-top {
        margin-top: 1rem;
      }     
    }
  }

  .paymentContainer {
    //margin: 0;
    padding: 0;
    width: 100%;
    margin-bottom: 40px;
    @media screen and (max-width: 1023.9px) {
      display: flex;
      flex-direction: column;
    }
    .MuiGrid-item {
      margin: 0;
      padding: 0;

      p {
        &:first-child {
          font-family: $font-family-Inter;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 21px;
          letter-spacing: normal;
          color: $bs-strong-black-100;
        }

        &:last-child {
          font-family: $font-family-Poppins;
          font-size: 23px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 31px;
          letter-spacing: normal;
          color: $bs-strong-black-100;
          margin-top: 16px;
          //margin-bottom: 32px;
          word-wrap: break-word;
        }
      }
    }
    .expiration {
      p {
        &:first-child {
          font-family: $font-family-Inter;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 21px;
          letter-spacing: normal;
          color: $bs-strong-black-100;
        }
        &:last-child {
          font-family: $font-family-Poppins;
          font-size: 23px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 31px;
          letter-spacing: normal;
          color: #000000;
          margin-top: 16px;
          // margin-bottom: 32px;
          word-break: break-word;
        }
      }
    }
  }

  button.paymentButton.MuiButtonBase-root:last-child {
    margin: 0px 0px 0px 0px;
    padding: 0;
    font-family: $font-family-Poppins;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: $bs-strong-black-100;
    text-transform: none;

    &:hover {
      background: $bs-strong-black-100 !important;
      color: #ffffff !important;
    }

    @media screen and (max-width: 767.9px) {
      width: 100%;
    }
  }

  .paymentCard {
    .header {
      span {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      p {
        font-family: $font-family-PoppinsSemiBold;
        font-size: 19px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 27px;
        letter-spacing: normal;
        color: $bs-strong-black-100;
        margin: 0;
      }
    }
  }

  .upcominingAppointment {
    .title {
      font-family: $font-family-PoppinsSemiBold;
      font-size: 19px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 27px;
      letter-spacing: normal;
      color: $bs-strong-black-100;
    }

    hr {
      margin: 15px 0px 32px 0px;
    }

    .upcomingContainer {
      @media screen and (max-width: 1023.9px) and (min-width: 768px) {
        grid-template-rows: auto auto;
        grid-template-columns: 1fr 1fr;        
      }
      div:first-child {
        @media screen and (max-width: 1023.9px) and (min-width: 768px) {
          grid-row: 1;
          grid-column: 1/2 span;     
        }
      }
      @media screen and (min-width:767px) {
        .status-container {
          margin-left: auto;
          padding-right: 30px;
        }
      }   
      @media screen and (min-width:1024px) {
        width: 584px;
        display: flex;
        justify-content: space-between;
        gap: 40px;            
        .appointment-date-container {
          margin-right: 14px;
        }
        .status-container { 
          padding-right: 0;
        }
      }
      @media screen and (min-width:1280px) {
        width: 604px;          
      }

      p {
        margin: 0;
        padding: 0;

        &:first-child {
          font-family: $font-family-Inter;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 21px;
          letter-spacing: normal;
          color: $bs-strong-black-100;
          margin-bottom: 16px;
          @media screen and (max-width: 767.9px) { 
            margin-bottom: 8px;
          }
        }

        &:last-child {
          font-family: $font-family-PoppinsSemiBold;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 24px;
          letter-spacing: normal;
          color: $bs-strong-black-100;
          margin-bottom: 40px;
          text-transform: capitalize;
          @media screen and (max-width: 767.9px) { 
            margin-bottom: 32px;
            font-size: 23px;
            line-height: 31px;
          }
          span {
            font-family: $font-family-PoppinsSemiBold;
            margin-left: 24px;
            @media screen and (max-width: 767.9px) { 
              margin-bottom: 32px;
              font-size: 23px;
              line-height: 31px;
              margin-left: 37px;
            }
          }
        }
      }
    }

    .upcomingButton {
      @media screen and (max-width: 767.9px) { 
        display: flex;
        flex-direction: column-reverse;
        gap: 24px;
      }
      button {
        margin: 0;
        padding: 0;

        &:hover {
          background: $bs-strong-black-100 !important;
          color: #ffffff !important;
        }

        @media screen and (max-width: 767.9px) {
          width: 100%;
        }

        &:last-child {
          margin-left: 24px;

          @media screen and (max-width: 1023.9px) and (min-width: 768px) {
            margin-top: 24px;
            margin-left: 0;
          }

          @media screen and (max-width: 767.9px) {
            margin-top: 8px;
            margin-left: 0;
          }
        }
      }
    }

    .notice {
      font-family: $font-family-Inter;
      font-size: 14px;
      font-weight: normal;     
      font-style: normal;
      line-height: 21px;
      letter-spacing: normal;
      color: $bs-strong-black-100;
      margin-top: 40px;
    }
  }

  .appointmentHistory {
    .title {
      font-family: $font-family-PoppinsSemiBold;
      font-size: 19px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 27px;
      letter-spacing: normal;
      color: $bs-strong-black-100;
    }

    hr {
      margin: 16px 0px 11px 0px;
    }

    table {
      tbody {
        tr {
          height: 72px;
          align-items: center;

          td,
          span {
            padding: 0;
            font-family: $font-family-Inter;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: $bs-strong-black-100;
          }
          &.no-past-appointments {
            height: fit-content;
            td {
              font-weight: 600!important;
              font-family: $font-family-Poppins;
              padding-top: 24px;
              @media screen and (min-width:1280px) {
                padding-bottom: 24px;
              }
              @media screen and (max-width:767.9px) {
                padding-top: 21px;
              }
            }
          }
        }       
      }
    }
  }

  .supportTopics {
    .title {
      font-family: $font-family-PoppinsSemiBold;
      font-size: 19px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.42;
      letter-spacing: normal;
      color: $bs-strong-black-100;
    }

    .supportTopicsBody {
      gap: 20px;
      margin-top: 3px;

      .supportTopicsCard {
        border-radius: 19px;
        border: solid 1px #7f7f7f;
        padding: 32px 20px;

        p, h3 {
          font-family: $font-family-PoppinsSemiBold;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $bs-strong-black-100;
        }

        hr {
          margin: 11.5px 0px 23px 0px;
        }

        div {
          gap: 16px;
          a {
            font-family: $font-family-Inter;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: $bs-strong-black-100;
          }
        }

        button {
          margin-top: 24px;

          &:hover {
            background: $bs-strong-black-100 !important;
            color: #ffffff !important;
          }
        }
      }
    }
  }
  .HelpsupportTitle {
    display: none;
  }
  
  @media (max-width:767.9px) {
    .HelpsupportTitle {
      display: block;
      font-size: 23px!important;
      font-style: normal;
      font-weight: 600!important;
      margin-bottom: 32px!important;
      padding-left: 16px;
      color: $bs-strong-black-100;
      line-height: 32px!important;
      font-family: $font-family-Poppins;
    }
  }

  .supportTools {
    background: #ffffff;

    .title {
      font-family: $font-family-PoppinsSemiBold;
      font-size: 19px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 27px;
      letter-spacing: normal;
      color: $bs-strong-black-100;
    }
    .supportToolsBody {
      margin-top: 3px;
    }
    .cardMain {
      gap: 24px;

      .card:hover {
        background-color: #ffc800;
        border: 4px solid transparent;
      }

      .card {
        border-radius: 24px;
        height: 160px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.24);
        @media screen and (max-width:767.9px) {
          height: 125px;
        }

        p {
          font-family: $font-family-PoppinsSemiBold;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 24px;
          letter-spacing: normal;
          text-align: center;
          color: $bs-strong-black-100;
          margin-top: 12px;
        }
      }
    }
  }
}

/*View Statement Popup*/
.viewStatement {
  &::before {
    content: "FPO";
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 50%;
    position: absolute;
    opacity: 0.5;
    font-family: $font-family-Poppins;
    font-size: 120px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #c5a5cf;
  }

  padding: 60px 40px;
  background: #ead1f5;

  @media screen and (min-width: 1024px) {
    width: 615px;
  }

  @media screen and (min-width: 768px) and (max-width: 1023.9px) {
    width: 504px;
  }

  .cursor-pointer {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .billedTo {
    margin: 30px 0px;

    p {
      &:first-child {
        font-family: $font-family-Inter;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: $bs-strong-black-100;
      }

      &:last-child {
        font-family: $font-family-PoppinsSemiBold;
        font-size: 23px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.04;
        letter-spacing: normal;
        color: $bs-strong-black-100;
        margin-top: 12px;
      }
    }
  }

  .charges {
    margin-bottom: 30px;

    .MuiBox-root {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      border-top: solid 0.5px $bs-strong-black-100;
      padding: 10px 0;

      p {
        font-family: $font-family-Inter;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: $bs-strong-black-100;
      }

      &:first-child {
        border-top-style: none;

        p {
          font-family: $font-family-Inter;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: $bs-strong-black-100;
        }
      }

      &:last-child {
        border-top: solid 3.5px $bs-strong-black-100;

        //border-bottom-style: none;
        p {
          font-family: $font-family-InterSemiBold;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #000;
        }
      }
    }
  }

  .buttons {
    @media screen and (max-width: 767.9px) {
      flex-direction: column;
      display: flex;
    }

    button {
      margin: 0;
      padding: 0;
      height: 50px;
      font-family: $font-family-Poppins;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: center;
      color: $bs-strong-black-100;
      box-shadow: none;

      &:hover {
        background: $bs-strong-black-100 !important;
        color: #ffffff !important;
      }

      &:last-child {
        margin-left: 20px;

        @media screen and (max-width: 767.9px) {
          margin-left: 0px;
          margin-top: 20px;
        }
      }
    }
  }

  .bottomSection {
    border-radius: 18px;
    border: solid 1px #7f7f7f;
    padding: 14px 20px;
    margin-top: 40px;

    .dollarBtn {
      background-image: linear-gradient(142deg, #ffe600 5%, #ff7108 95%);
      float: left;

      svg {
        color: $bs-strong-black-100;
      }
    }

    .referDiv {
      padding-left: 72px;

      p {
        font-family: $font-family-Inter;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #000;

        a {
          font-family: $font-family-Inter;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: #000;
          text-decoration-color: $bs-strong-black-100;
        }

        &:first-child {
          font-family: $font-family-PoppinsSemiBold;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $bs-strong-black-100;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.yesDelete {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 24px;

  @media screen and (max-width: 767.9px) {
    flex-direction: column;
  }

  button {
    box-shadow: none;
    font-family: $font-family-Poppins;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    height: 48px;

    @media screen and (max-width: 767.9px) {
      width: 100%;
      margin: 0;
      padding: 0;

      &:last-child {
        margin-top: 20px;
      }
    }

    &:hover {
      box-shadow: none;
      background: $bs-strong-black-100;
      color: #ffffff;
    }
  }
}

.chatDiv {
  button {
    &:hover {
      background: $bs-strong-black-100;
      color: #ffffff !important;
    }

    @media screen and (max-width: 767.9px) {
      width: 100% !important;
    }
  }
}

div.saveChangesBtn {
  margin-top: 40px !important;

  button {
    margin: 0 !important;
    padding: 0 !important;
    /* margin-bottom: 20px !important; */

    &:first-child {
      margin-right: 24px !important;
    }

    @media screen and (max-width: 767.9px) {
      width: 100%;
      margin: 0;
      padding: 0;

      &:first-child {
        margin-right: 0px !important;
        // margin-bottom: 20px !important;
      }
    }
  }
}

.nameForm {
  margin-top: 24px !important;
  .userName {
    width: 305px;
    margin-right: 16px;
  }
  .firstName {
    // margin-right: 16px;
  }

  .lastName {
    margin-left: 24px;
  }

  .firstName,
  .lastName {
    width: 303px;

    @media screen and (max-width: 1023.9px) and (min-width: 768px) {
      width: 263px;
    }
    @media screen and (max-width: 1279.9px) and (min-width: 1024px) {
      width: 300px;
    }
  }
}

.paymentspanel {
  .phone {
    input {
      width: 604px;

      @media screen and (max-width: 1023.9px) and (min-width: 768px) {
        width: 550px;
      }
    }
  }
  .addressForm {
    display: flex;
    //align-items: center;
    justify-content: space-between;
    width: 630px;
    // margin-top: 40px !important;
    @media screen and (max-width: 1279.9px) and (min-width: 1024px) {
      width: 624px;
    }

    .serviceAddress {
      width: 298px;
      margin-right: 16px;
    }
    .mailingAddress {
      margin-left: 16px;
    }
    // .leftDiv {
    //   width: 100%;
    // }
    .leftDiv {
      height: 68px;
      width: 303px;
      margin-top: 24px;
    }
    .rightDiv {
      width: 100%;
    }
  }

  .mailCheck {
    padding-left: 0;
  }

  .phoneForm {
    display: flex;
    //align-items: center;
    justify-content: space-between;
    width: 630px;
    margin-top: 24px !important;
    @media screen and (max-width: 1279.9px) and (min-width: 1024px) {
      width: 624px;
    }

    .phoneNumber {
      width: 298px;
      margin-right: 16px;
    }
    .secondaryPhoneNumber {
      margin-left: 16px;
    }

    @media screen and (max-width: 1023.9px) and (min-width: 768px) {
      width: 550px;
    }
    @media screen and (max-width: 1279.9px) and (min-width: 1024px) {
      width: 624px;
    }
    .leftDiv {
      width: 100%;
      .userEmail {
        font-family: $font-family-PoppinsSemiBold;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $bs-strong-black-100;
        padding-top: 5px;
      }
    }

    .rightDiv {
      width: 100%;
    }
  }
  .emailForm {
    display: flex;
    //align-items: center;
    justify-content: space-between;
    width: 630px;
    @media screen and (max-width: 1279.9px) and (min-width: 1024px) {
      width: 624px;
    }
    .secondaryMail {
      margin-left: 16px;
    }
    .primaryMail {
      margin-right: 16px;
    }

    @media screen and (max-width: 1023.9px) and (min-width: 768px) {
      width: 550px;
    }

    margin-top: 24px !important;

    .leftDiv {
      width: 100%;
      .userEmail {
        font-family: $font-family-PoppinsSemiBold;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $bs-strong-black-100;
        padding-top: 5px;
      }
    }

    .rightDiv {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1023.9px) {
  .drawerActive {
    .paymentspanel {
      .phone {
        input {
          width: 100%;
        }
      }

      .emailForm {
        flex-direction: column;
        width: 100%;

        .leftDiv {
          width: 100%;
        }

        .rightDiv {
          width: 100%;
          margin-top: 24px!important;
        }
        .secondaryMail {
          margin-left: 0px;
          margin-top: 24px;
        }
        .primaryMail {
          margin-right: 0px;
          margin-top: 24px;
        }
      }

      .addressForm {
        flex-direction: column;
        width: 100%;

        .leftDiv {
          width: 100%;
        }

        .rightDiv {
          width: 100%;
        }
        .mailingAddress {
          margin-left: 0px;
          margin-top: 24px !important;
        }
      }
    }

    .phoneForm {
      flex-direction: column;
      width: 100%;

      .leftDiv {
        width: 100%;
      }

      .rightDiv {
        width: 100%;
      }
      .phoneNumber {
        width: 100%;
      }
      .secondaryPhoneNumber {
        margin-left: 0px;
        margin-top: 24px !important;
      }
    }

    .rightDiv {
      margin-top: 24px;
    }

    .nameForm {
      .lastName {
        margin-top: 24px!important;
      }

      .firstName,
      .userName,
      .lastName {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

.saveChangesBtn,
.stepper {
  button {
    &:hover {
      background: $bs-strong-black-100;
      color: #ffffff !important;
    }
  }
}

.paymentMethodButtons {
  margin-bottom: 0px;

  @media screen and (max-width: 767.9px) {
    display: flex;
    flex-direction: column;
  }

  button {
    //margin: 0!important;
    // padding: 0!important;
    width: 240px;
    height: 50px;
    box-shadow: none;

    //  margin-top: 40px!important;
    @media screen and (max-width: 767.9px) {
      width: 100% !important;
    }

    &:last-child {
      // margin-left: 20px !important;

      @media screen and (max-width: 767.9px) {
        margin-left: 0px !important;
      }
    }

    // &:hover {
    //   background: $bs-strong-black-100;
    //   color: #ffffff !important;
    // }
  }
}

.addressOne,
.addressTwo {
  width: 630px;

  @media screen and (max-width: 1023.9px) and (min-width: 768px) {
    width: 376px;
  }
  @media screen and (max-width: 767.9px) {
    width: 100%;
  }
}

.addressOne {
  margin-top: 24px !important;
}

.addressTwo {
  margin: 24px 0px !important;
}

.drawerActive {
  .paymentMethodButtons {
    @media screen and (max-width: 1023.9px) and (min-width: 768px) {
      display: flex;
      flex-direction: column;
    }

    button {
      &:last-child {
        margin-left: 20px !important;

        @media screen and (max-width: 767.9px) {
          margin-left: 0px !important;
        }

        @media screen and (max-width: 1023.9px) and (min-width: 768px) {
          margin-left: 0px !important;
        }
      }
    }
  }
}

.loginMain {
  margin: auto;
 
  @media screen and (min-width: 768px) {
    width: 571px;    
    margin-bottom: 96px;
  }

  @media screen and (min-width:1024px) {   
    margin-top: 80px;
  }

  @media screen and (max-width: 1023.9px) and (min-width: 768px) {   
    margin-top: 128px;
  }
 
  @media screen and (max-width: 767.9px) {
    width: calc(100% - 32px);   
    margin-top: 112px;   
    margin-bottom: 80px;
  }
  .homeButton.disabled {
    &:hover {
      background: #fff1bf !important;
      color: #4d4d4d !important;
      box-shadow: none !important;
    }
  }
  .homeButton {
    margin-top: 40px;
    width: 240px;
    height: 48px;
    border-radius: 24px;
    background-color: #fff1bf;
    box-shadow: none !important;
    font-family: $font-family-Poppins;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: center;
    color: $bs-strong-black-100;
    margin-left: 0;
    margin-bottom: 0;
    @media screen and (max-width: 767.9px) {
      width: 100%;
      margin-left: 0;
    }
  }

  .createAccount {
    font-family: $font-family-Inter;    
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $bs-strong-black-100;
    margin-top: 24px;

    .signup, a {
      text-decoration: underline;
      font-family: $font-family-Inter;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $bs-strong-black-100;
      cursor: pointer;
    }
  }

  .title {
    font-family: $font-family-PoppinsSemiBold;
    font-size: 33px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 43px;
    letter-spacing: normal;
    color: $bs-strong-black-100;
  }

  p.desc {
    margin-top: 16px;
  }

  .form label {
    margin-bottom: 4px;
    position: relative;
    &+.MuiInputBase-root {
      margin-top: 0;
    }
  }

  .form input {
    width: 571px;
    height: 28px;
    border: solid 1px #7f7f7f;
    padding: 5px 51.1px 5px 10px;
  }
  .errorMsgFormat {
    display: flex;
  }
  .inputFiledErrorImg {
    min-width: 13px;
    min-height: 13px;
    margin: 8px 5px 0 0;
    object-fit: contain;
    background: url("../images/icon-alert.svg") no-repeat left top;
  }
  .inputFiledErrorMsg {
    margin-top: 8px;
    font-family: $font-family-Inter;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #d11e00;
    /*padding-left: 21px;*/
  }
  .passwordCode {
    font-family: $font-family-Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    color: $bs-strong-black-100;
    span, a {
      text-decoration: underline;
      font-family: $font-family-Inter;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 24px;
      letter-spacing: normal;
    }
  }
  p.desc,
  .form label,
  .form input {
    font-family: $font-family-Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    color: $bs-strong-black-100;
  }

  .form {
    margin-top: 40px;
  }

  .form.secondForm {
    margin-top: 24px;
  }

  .rememberMe {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    span, a {
      font-family: $font-family-Inter;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $bs-strong-black-100;

      &:last-child {
        font-size: 16px;
        text-decoration: underline;
      }
    }
    a:hover{ text-decoration: none;}

    .MuiCheckbox-root {
      width: 23px;
      height: 23px;

      input {
        width: 23px;
        height: 23px;
      }
      svg {
        color: #7f7f7f;
        width: 23px;
        height: 23px;
      }
    }
  }

  .multiButtons {
    margin-top: 40px;
    gap: 24px;
    display: flex;
    justify-content: flex-start;

    @media screen and (max-width: 767.9px) {
      flex-direction: column-reverse;
    }

    button {
      margin: 0;
      padding: 0;
      box-shadow: none;
      width: 240px;
      height: 48px;
      font-family: $font-family-Poppins;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 16px;
      letter-spacing: normal;
      text-align: center;
      color: $bs-strong-black-100;
      border-radius: 24px;
      // margin-top: 40px;

      @media screen and (max-width: 767.9px) {
        width: 100%;
      }

      &:hover {
        background: $bs-strong-black-100 !important;
        color: #ffffff !important;
      }
    }

    button.leftBtn {
      border: solid 2px $bs-strong-black-100;
    }

    button.rightBtn {
      background: #fff1bf;
      // margin-left: 20px;
      &:hover {
        background: #fff1bf !important;
        color: #4d4d4d !important;
        box-shadow: none !important;
      }
      // @media screen and (max-width: 767.9px) {
      //   margin-left: 0px;
      // }
    }

    button.PrimaryColor {
      background: #ffc800;
      &:hover {
        background: $bs-strong-black-100 !important;
        color: #ffffff !important;
        box-shadow: none !important;
      }
    }
  }
  .homeButton.signUp,
  .signUp.PrimaryColor {
    background: #ffc800;
    &:hover {
      background: $bs-strong-black-100 !important;
      color: #ffffff !important;
      box-shadow: none !important;
    }
  }

  .radioGroup {
    margin-top: 60px;

    p {
      font-family: $font-family-Inter;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $bs-strong-black-100;
      display: flex;
      margin-bottom: 16px;
      align-items: center;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  hr.logindivider {
    // &.forgotUserDivider  {
    //   margin: 64px 0 32px 0;
    //   border: 1px solid #7f7f7f;
    // }
    margin: 64px 0 32px 0;
    border-color: #7f7f7f;
    color: #7f7f7f;
    background-color: #7f7f7f;
    opacity: 1;
  }

  .loginbottomSections {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    @media screen and (max-width: 767.9px) {
      flex-direction: column;
    }

    .needHelp,
    .otherAccounts {
      p,a{
        font-family: $font-family-Inter;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        margin-top: 12px;
        text-decoration: underline;
        cursor: pointer;
        color: $bs-strong-black-100;
      }
      a {
        display: block;
        color: #000;
      }
      p:hover,a:hover {
        text-decoration: none;
      }

      h2{font-size: 19px; line-height: 27px}

      span.title {
        font-family: $font-family-PoppinsSemiBold;
        font-size: 19px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
      }
    }

    .otherAccounts {
      @media screen and (max-width: 767.9px) {
        margin-top: 42.8px;
      }

      .otherCombo {
        margin-top: 12px;

        div {
          #accountPreferences {
            border-style: none !important;
            font-family: $font-family-InterSemiBold;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            min-height: auto;
          }
        }

        .MuiOutlinedInput-root,
        .MuiOutlinedInput-notchedOutline {
          // border: 0.5px solid $bs-strong-black-100;

          width: 270px;
          font-family: $font-family-InterSemiBold;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;

          @media screen and (max-width: 767.9px) {
            width: 100%;
          }

          legend {
            display: none;
          }
        }
      }
    }
  }
  .loginpwdtitle {
    margin: 0 0 40px 0;
  }

  .leftChevron {
   
    margin: 0 0 40px 0;
    display: flex;

    &.forgotUserName {
    margin: 0 0 40px 0;
    display: flex;
    }
    button {
      margin: 0;
      padding: 0;
    }

    span {
      text-decoration: underline;
      margin-left: 8px;
      font-family: $font-family-Inter;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $bs-strong-black-100;
    }
  }
  .accountPhoneNumber {
    margin-top: 40px;
    label {
      color: $bs-strong-black-100;
    }
  }

  .homeButton.signUp {
    margin: auto !important;
  }

  .strengthBar {
    div {
      div {
        &:nth-child(odd) {
          height: 10px !important;
          /*background: #646464 !important;*/
          border-radius: 10px;
        }
      }
    }
  }

  .MuiButtonBase-root {
    text-transform: none !important;
  }
}

// QBP Non Auth landing page CSS

.OverallPage .loginMain {

  .desc {
    margin-bottom: 40px;
  }

  .userInputFormTop {
    &>:first-child {
      margin-bottom: 24px;
    }

    .MuiInputBase-input {
      height: 38px;
      line-height: 24px;
      padding: 0 12px;
    }
  }

  .modalBoxDescInput {
    color: $bs-strong-black-100;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    position: relative;

    &+.MuiInputBase-root {
      margin-top: 4px;
    }
  }

  .requiredLabel {
    line-height: 16px;
    margin-top: 16px;
    font-size: 12px;
  }

  .rightBtn {
    @media screen and (max-width:767.9px) {
      width: 100%;
    }
  }

  .errorMsgFormat {
    display: flex;
  }

  .inputFiledErrorImg {
    min-width: 13px;
    min-height: 13px;
    margin: 8px 5px 0 0;
    object-fit: contain;
    background: url("../images/icon-alert.svg") no-repeat left top;
  }

  .inputFiledErrorMsg {
    margin-top: 8px;
    font-family: $font-family-Inter;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #d11e00;   
  }

  .loginbottomSections {
    .needHelp,
    .otherAccounts {
      p {
        color: $bs-strong-black-100;
        font-family: $font-family-Inter;
        font-weight: 400;
      }

      p:hover {
        text-decoration: none;
      }

      span.title {
        line-height: 27px;
        letter-spacing: normal;
      }
    }

    .otherAccounts {
      @media screen and (max-width: 767.9px) {
        margin-top: 42.8px;
      }

      .otherCombo {
        margin-top: 12px;

        div {
          #accountPreferences {
            border-style: none !important;
            font-family: $font-family-InterSemiBold;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            min-height: auto;
          }
        }

        .MuiOutlinedInput-root,
        .MuiOutlinedInput-notchedOutline {
          width: 270px;
          font-family: $font-family-InterSemiBold;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;

          @media screen and (max-width: 767.9px) {
            width: 100%;
          }

          legend {
            display: none;
          }
        }
      }
    }
  }
}

.forgetUserNameError {
  @media screen and (min-width: 1280px) {
    width: 968px;
    align-items: center;
  }

  @media screen and (max-width: 1279.9px) and (min-width: 1024px) {
    width: 776px;
    align-items: center;
  }

  @media screen and (max-width: 1023.9px) and (min-width: 768px) {
    width: 456px;
    align-items: start;
  }
}

.errorMsgFormat {
  display: flex;
  // max-height: 20px;
}

.inputFiledErrorImg {
  min-width: 13px;
  min-height: 13px;
  margin: 8px 5px 0 0;
  object-fit: contain;
  background: url("../images/icon-alert.svg") no-repeat left top;
}
.inputFiledErrorMsg {
  margin-top: 8px;
  font-family: $font-family-Inter;
  font-size: 12px !important;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px!important;
  letter-spacing: normal;
  color: #d11e00 !important;
  /*padding-left: 21px;*/
  white-space: pre-wrap;
}
.mailCheck,
.addNewPaymentMethod,
.editNewPaymentMethod,
.app-download-do-no-see-container,
.loginMain .rememberMe,
.termsCheckboxWrapper,
.autopayNote {
  .checkBoxLabel:after {
    left: 9px;
    bottom: 6px;
    width: 8px;
    height: 18px;
    border: solid #787878;
    border-width: 0px 3px 3px 0;
    transform: rotate(45deg);
  }
  input:checked ~ .checkBoxLabel:before {
    background: #ffffff;
    width: 10px;
    height: 15px;
    content: "";
    position: absolute;
    right: -4px;
    top: -7px;
  }
  label.checkboxMain {
    font-family: $font-family-Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 21px;
    letter-spacing: normal;
    color: #000;
    // margin-top: 24px;
    width: 100%;
    // padding: 13px 54.3px 13px 14px;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 24px !important;

    span,
    a {
      font-family: $font-family-Inter;
      font-size: 14px;
    }
    a {
      margin-left: 5px;
    }
  }

  input[type="checkbox"] {
    // visibility: hidden;
    opacity: 0.1;
    position: absolute;
    left: 0px;
    width: 18px;
    height: 18px;
    top: 2px;
    border:solid 1px #fff;
  }
  .checkboxActive {
    background: #f1f1f1;
  }
  span.checkBoxLabel {
    position: absolute;
    top: 2px;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid #7f7f7f;
    border-radius: 4px;
  }
  input:checked ~ .checkBoxLabel {
    background-color: #ffffff;
  }
  .checkBoxLabel:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ .checkBoxLabel:after {
    display: block;
  }
}
.MuiDialog-paper {
  @media screen and (min-width: 1280px) {
    width: 616px;
  }

  @media screen and (max-width: 1279.9px) and (min-width: 1024px) {
    width: 616px;
  }

  @media screen and (max-width: 1023.9px) and (min-width: 768px) {
    width: 616px;
  }

  @media screen and (max-width: 767.9px) {
    width: calc(100% - 32px);
    max-width: calc(100% - 32px) !important;
  }
}

.MuiDialog-container {
  align-items: flex-start!important;
  .MuiDialog-paper {
    border-radius: 24px!important;
    margin-top: 80px!important;
    vertical-align: top;
    @media screen and (max-width: 767.9px) {
      margin: 16px;
    }
  }
}
/*View Statement Popup*/

.loginMain .Mui-disabled {
  background: #fff1bf !important;
  color: #4d4d4d !important;
}
.homeButton.btnEnabled,
button.rightBtn.btnEnabled {
  color: $bs-strong-black-100;
}
.loginMain .homeButton,
.loginMain .multiButtons button.rightBtn {
  background-color: #ffc800;
  &:hover {
    background-color: $bs-strong-black-100 !important;
    color: #ffffff !important;
  }
}
.suceesmessage {
  text-align: center;
  // .title {
  //   margin-top: 16px;
  // }
  .desc {
    margin-bottom: 40px !important;
  }
  .createAccount {
    font-family: $font-family-Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: center;
    color: $bs-strong-black-100;
  }
}

.MuiFormLabel-root.MuiInputLabel-root {
  transform: none !important;
  font-family: $font-family-Inter;
}
span.MuiTypography-root.MuiTypography-body1.forgotUsername {
  text-decoration: underline;
  cursor: pointer;
}
span.MuiTypography-root.MuiTypography-body1.forgotPassword {
  text-decoration: underline;
  cursor: pointer;
}
span.MuiTypography-root.MuiTypography-body1.forgotUsername:hover,
span.MuiTypography-root.MuiTypography-body1.forgotPassword:hover,
.loginMain .createAccount .signup:hover {
  text-decoration: none;
}

.showErrorBorder > input,
.showErrorBorder_ConfirmPassword > input {
  border: solid 1px #d11e00 !important;
}
/*Login Page Header CSS*/

#signIn-menu,
#forHome-menu {
  top: 110.5px !important;
  div.MuiBackdrop-root.MuiBackdrop-invisible {
    top: 110.5px !important;
  }
}
#support-menu {
  top: 41px !important;
  width: 283px !important;
  div.MuiBackdrop-root.MuiBackdrop-invisible {
    top: 41px !important;
    width: 283px !important;
  }
}
.loginHeaderMain {
  hr {
    display: none;
  }
  .loginHeaderBottomLeft {
    a {
      margin-left: 0px;
    }
  }
  button {
    gap: 8px;
    span {
      margin: 0px;
    }
  }
  .loginHeaderTop {
    border-bottom: 1px solid #b2b2b2;
    a {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .loginHeaderTop {
    a,
    button {
      border-radius: 0;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }
  }
  a,
  button {
    text-transform: none !important;
    margin-left: 40px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #4d4d4d !important;
    padding: 0px;
    margin-top: 0;
    margin-bottom: 0;
    font-family: $font-family-Poppins;
  }
  .loginHeaderBottomRight {
    a,
    button {
      font-family: $font-family-Poppins;
      color: #000000 !important;
    }
  }
  button:hover {
    // text-decoration: underline;
    // text-decoration-thickness: 4px;
    // -webkit-text-decoration-color: #000;
    // text-decoration-color: #000;
    color: #000;
  }
  .loginHeaderTop {
    display: flex;
    justify-content: flex-end;
    padding: 0 44px;
    position: relative;
    height: 39.5px;
    button {
      border-top: 3px solid #ffffff;
      border-bottom: 3px solid #ffffff;
      a {
        margin: 0px;
        text-decoration: none;
      }
    }
    button:hover {
      //text-underline-offset: 12px;
      border-bottom: 6px solid #000000;
    }
    button[aria-expanded="true"] {
      // text-underline-offset: 12px;
      // text-decoration-thickness: 4px;
      // text-decoration-line: underline;
      border-bottom: 6px solid #000000;
    }
  }
  .loginHeaderBottom {
    height: 69.5px;
    align-items: center;
    padding: 0 44px;
    .loginHeaderBottomMain {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 69.5px;
      button {
        border-top: 6px solid #ffffff;
        border-bottom: 6px solid #ffffff;
        min-height: 69px;
        border-radius: 0;
        border-color: transparent;
        a {
          margin: 0px;
          text-decoration: none;
        }
      }
      button:hover {
        // text-underline-offset: 26.5px;
        border-bottom: 6px solid #000000;
      }
      button[aria-expanded="true"] {
        border-bottom: 6px solid #000000;
        // text-underline-offset: 26.5px;
        // text-decoration-thickness: 4px;
        // text-decoration-line: underline;
      }
    }
  }

  // button :: span {
  //   text-underline-offset: 26px;
  //   text-decoration-thickness: 4px;
  //   text-decoration-line: underline;
  // }

  .upwardArrowIcon {
    transform: rotate(180deg);
    // text-underline-offset: 26px;
    // text-decoration-thickness: 4px;
    // text-decoration-line: underline;
  }
}

.headerMenuListOpen
  > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
  top: -2px !important;
  border-radius: 0;
  @media screen and (min-width: 1024px) {
    width: 250px;
  }
  ul {
    padding: 35px 0px 20px 0px;
    li {
      font-weight: 500;
      font-size: 14px !important;
      padding: 10px 32px;
      color: #000 !important;
      line-height: 21px;
      font-family: $font-family-Poppins;
    }
  }
}
.topHeaderMenuListOpen
  > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
  top: -1px !important;
  ul {
    padding: 25px 0px 10px 0px;
    li {
      font-weight: 500;
      font-size: 14px !important;
      padding: 10px 20px;
      color: #000 !important;
      line-height: 21px;
      font-family: $font-family-Poppins;
    }
  }

  // button :: span {
  //   text-underline-offset: 26px;
  //   text-decoration-thickness: 4px;
  //   text-decoration-line: underline;
  // }

  .upwardArrowIcon {
    transform: rotate(180deg);
    // text-underline-offset: 26px;
    // text-decoration-thickness: 4px;
    // text-decoration-line: underline;
  }
}

.headerMenuListOpen
  > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
  top: -2px !important;
  border-radius: 0;
  @media screen and (min-width: 1024px) {
    width: 250px;
  }
  ul {
    padding: 35px 0px 20px 0px;
    li {
      font-weight: 500;
      font-size: 14px !important;
      padding: 10px 32px;
      color: #000 !important;
      line-height: 21px;
      font-family: $font-family-Poppins;
    }
  }
}
.topHeaderMenuListOpen
  > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
  top: -1px !important;
  ul {
    padding: 25px 0px 10px 0px;
    li {
      font-weight: 500;
      font-size: 14px !important;
      padding: 10px 20px;
      color: #000 !important;
      line-height: 21px;
      font-family: $font-family-Poppins;
    }
  }
}
.loginHeaderMobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  @media screen and (max-width: 1023.9px) and (min-width: 768px) {
    margin: 0px 40px;
  }

  @media screen and (max-width: 767.9px) {
    margin: 0px 16px;
  }
}
button.availabilityBtn {
  width: 240px;
  height: 40px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: none !important;
  font-family: $font-family-GraphikRegular;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  text-align: center;
  color: $bs-strong-black-100;
  padding: 10px 24px;
  margin-left: 0px !important;
 
  // @media screen and (min-width: 768px) {
  //   width: auto;
  //   height: 41px;
  //   // margin: 20px 16px;
  // }
  // @media screen and (max-width: 1023.9px) {
  //   margin: 35px 0px 10px 0px;
  // }
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
}
.headerMenuList {
  ul {
    li {
      font-family: $font-family-Poppins;
      font-size: 14px;
    }
  }
}
.showMobileHeader:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0px;
  bottom: 0;
  background-color: #4d4d4d;
  opacity: 0.5;
  z-index: 10;
}
.mobileloginPagesidebar {
  padding: 32px 16px 0px 16px;
  .sideBarHeader {
    display: flex;
    justify-content: space-between;
    svg {
      cursor: pointer;
    }
  }
  p,
  a {
    text-transform: none !important;
    width: 100%;
    text-align: left;
    display: inline-block;
    color: #000;
    font-size: 14px;
    font-family: $font-family-Poppins;
  }
  text-transform: none !important;
  .sideBarHeaderTop {
    margin-top: 24px;
    a {
      min-height: 0px;
      height: auto;
      text-decoration: none;
    }

    button {
      // height: auto;
      padding-left: 0;
      width: 100%;
    }
    display: flex;
    flex-direction: column;
  }
  .sideBarHeaderBottom {
    background: #f2f3f3;
    margin-top: 25px;
    width: calc(100% + 36px);
    margin-left: -16px;
    padding: 24px 16px 16px 16px;
    a {
      min-height: 35px;
    }
    div {
      background: #f2f3f3;
    }
  }
  .MuiAccordion-region {
    .MuiAccordionDetails-root {
      padding: 0px 16px;
      p {
        margin-top: 12px;
      }
    }
  }
}
/*Login Page Header CSS*/
/*New Payment Method*/
.paypalDesc {
  .title {
    font-family: $font-family-PoppinsSemiBold;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    margin-top: 18.5px;
    color: $bs-strong-black-100;
  }
  .desc {
    font-family: $font-family-Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    margin-top: 12px;
    color: $bs-strong-black-100;
  }
  img {
    margin-top: 32px;
  }
  .checkBox {
    margin-top: 32px;
    display: flex;
    p {
      font-family: $font-family-Inter;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
    }
    // label.checkboxMain {
    //   padding-left: 12px !important;
    // }
  }
}
.payPalBtns {
  margin: 40px 0px 60px 20px;
  @media screen and (max-width: 1023.9px) {
    margin-left: 0px;
  }
  button {
    height: 50px;
    width: 240px !important;
    box-shadow: none;
    font-family: $font-family-Poppins;
    letter-spacing: normal;
    margin: 0;
    padding: 0;
    @media screen and (max-width: 767.9px) {
      width: 100% !important;
    }
    @media screen and (min-width: 768px) and (max-width: 1023.9px) {
      width: 225px !important;
    }
    &:last-child {
      margin-left: 20px;
      @media screen and (max-width: 767.9px) {
        margin-left: 0px;
        margin-top: 20px;
      }
    }
  }
  span {
    font-family: $font-family-PoppinsBold;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: $bs-strong-black-100;
  }
}
/*New Payment Method*/
/*Message Technition*/
.mrgTechPopup {
  padding: 64px 40px 48px;
  @media screen and (max-width:767.9px) {
    padding: 64px 24px 48px;
  }
  position: relative;
  .mrgTechTop {
    position: absolute;
    top: 24px;
    right: 24px;
  }
  .msgBoxForm {
    input {
      height: 90px;
      border: solid 1px #7f7f7f;
      padding: 8px 15px;
      font-family: $font-family-Inter;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #646464;
    }
    .MuiInputBase-root.MuiOutlinedInput-root {
      border-radius: 0;
      padding: 9px 8px 10px 14px;
      border: 1px solid #7F7F7F;
      fieldset {
        border: 0;
      }
    }
  }
  .msgTechButtons {
    margin-top: 32px;
    display: flex;
    @media screen and (max-width: 767.9px) {
     flex-direction: column-reverse;
     gap: 24px;
    }
    button {
      height: 48px;
      width: 240px !important;
      box-shadow: none;
      font-family: $font-family-Poppins;
      letter-spacing: normal;
      margin: 0;
      padding: 0;
      // @media screen and (max-width: 767.9px) {
      //   width: 100% !important;
      // }
      @media screen and (min-width: 768px) and (max-width: 1023.9px) {
        width: 200px !important;
      }
      &:last-child {
        margin-left: 24px;
        @media screen and (max-width: 767.9px) {
          margin-left: 0px;
          // margin-top: 20px;
        }
      }
      &:hover {
        background: $bs-strong-black-100;
        color: #ffffff;
      }
    }
    span {
      font-family: $font-family-PoppinsBold;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: center;
      color: $bs-strong-black-100;
    }
  }
  hr {
    margin: 16px 0px 32px 0px;
  }
  .msgBoxTitle {
    font-family: $font-family-Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    color: $bs-strong-black-100;
    margin-bottom: 4px;
  }
  .title {
    font-family: $font-family-PoppinsSemiBold;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 27px;
    letter-spacing: normal;
    color: $bs-strong-black-100;
  }
}
/*Message Technition*/
/*Schedule Appointment*/
.main-container {
  .title {
    font-family: $font-family-PoppinsSemiBold;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 27px;
    letter-spacing: normal;
    color: $bs-strong-black-100;
    //margin-bottom: 10px;
  }
  hr.firstDivider {
    border: solid 1px $bs-strong-black-100 !important;
    opacity: unset;
    margin-bottom: 32px;
  }
  div.newAppointmentv.MuiBox-root {
    background: #ffffff;
    padding: 48px 24px;
    @media screen and (max-width: 767.9px) {
      padding: 48px 16px;
    }
    .buttonSection {
      display: flex;
      flex-direction: row;
      margin-top: 40px;
      gap: 24px;
      @media screen and (max-width: 1023.9px) {
        flex-direction: column-reverse;        
      }
      button {
        &:hover {
          &:not(.MuiButtonBase-root.Mui-disabled) {
            background: $bs-strong-black-100;
            color: #ffffff;
          }
        }
      }
      button {
        margin: 0;
        padding: 0;
        @media screen and (max-width: 767.9px) {
          width: 100% !important;
        }
      }
    }
    .calendarContainer {
      // display: inline-block;
      // margin-top: 48px;
      // margin-bottom: 5px;
      @media screen and (max-width: 1023.9px) {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
      }

      .timeSlot {
        button {
          &:hover {
            &:not(.MuiButtonBase-root.Mui-disabled) {
              background: $bs-strong-black-100;
              color: #ffffff;
            }
          }
        }
        button {
          @media screen and (max-width: 1023.9px) {
            margin-left: 0;
            // padding-left: 0;
          }
          @media screen and (max-width: 767.9px) {
            min-width: 100% !important;
          }
          span {
            @media screen and (max-width: 767.9px) {
              width: 100% !important;
            }
          }
        }
      }
      .calendarMain {
        float: left;
        margin-right: 33px;
        @media screen and (max-width: 767.9px) {
          margin-bottom: 26px !important;
        }
        @media screen and (max-width: 1023.9px) {
          margin-bottom: 32px;
          margin-right: 0px;
        }
        @media screen and (min-width:1024px) {
          margin-top: 31px;
        }
        .MuiPickersCalendarHeader-root {
          margin-top: 0;
          @media screen and (max-width: 767.9px) {
            padding-left: 0px;
            max-height: fit-content;
          }
        }

        .MuiCalendarPicker-root {
          margin: 0;
        }
        .MuiButtonBase-root {
          font-family: $font-family-InterSemiBold;
          color: $bs-strong-black-100;
        }
        .MuiPickersCalendarHeader-label {
          font-family: $font-family-PoppinsSemiBold;
          font-size: 16.8px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
          color: $bs-strong-black-100;
        }
        .MuiButtonBase-root.Mui-disabled {
          font-family: $font-family-Inter;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
          color: #646464;
        }
        div.react-datepicker__day,
        div.react-datepicker__day-name {
          margin-right: 0;
          height: 39px;
          margin-bottom: 8px;
          width: 38px;         
        }
        div.react-datepicker__day {
          font-size: 16px;
          font-family: $font-family-Inter;
          font-weight: 600;
          line-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        div.react-datepicker__day--disabled, 
        div.react-datepicker__month-text--disabled, 
        div.react-datepicker__quarter-text--disabled, div.react-datepicker__year-text--disabled {
          font-weight: 400;
        }
       .react-datepicker__navigation-icon::before {
        border-width: 2px 2px 0 0;
       }      
       div.react-datepicker__current-month {
        font-size: 19px;
        line-height: 27px;
        margin-bottom: 37px!important;       
       }
        div.react-datepicker__week, 
        .react-datepicker__day-names {
          @media screen and (max-width:767.9px) {
            gap:15px;
          }
        }
      }
      .timeSlot {
        gap: 24px;
        > p {
          font-family: $font-family-Poppins;          
          line-height: 24px;
          letter-spacing: normal;
          @media screen and (min-width:1024px) {
            width: 220px;           
          }
        }
        button:first-of-type{
          margin-top: 6px;
          @media screen and (max-width: 767.9px) {
            margin-top: 15px;
          }
        }
      }
    }
    .tableContainer {
      display: flex;
      align-items: center;
      @media screen and (max-width: 767.9px) {
        flex-direction: column;
        align-items: flex-start;
        gap:28px;
      }
      // @media screen and (min-width:1024px) {
      //   max-width: 629px;
      // }
      .tableContainerInner {
        // margin-right: 15%;
        span {
          font-family: $font-family-Inter;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 21px;
          letter-spacing: normal;
          color: $bs-strong-black-100;
        }
        div {
          margin-top: 12px;
          @media screen and (max-width: 767.9px) {
            margin-top: 8px;
          }
        }
        div,
        span.time {
          font-family: $font-family-PoppinsSemiBold;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 24px;
          letter-spacing: normal;
          color: $bs-strong-black-100;
          @media screen and (max-width: 767.9px) {
            font-size: 23px;
            line-height: 31px;
          }
        }
        span.time {
          margin-left: 20px;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

div.tableContainer {
  @media screen and (min-width: 768px) and (max-width: 1023.9px) {
    display: inline-block !important;
  }

  .tableContainerInner {
    @media screen and (min-width: 768px) and (max-width: 1023.9px) {
      float: left;
    }
    &:first-child {
      @media screen and (min-width: 768px) and (max-width: 1023.9px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 26px;
      }
    }
  }
}
.weAreBusy {
  text-align: left;
  margin: 0 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-style: none !important;
  border-width: 0px;
}
ul.serviceAddressListUl {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
/*Schedule Appointment*/
.menuList {
  .MuiDivider-root.MuiDivider-fullWidth {
    margin: 47px 52px 59px 52px;
  }
  .userNameSideBar {
    p {
      font-family: $font-family-Poppins;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 36px;
      letter-spacing: normal;
      color: $bs-strong-black-100;
      text-transform: capitalize;
    }
  }
  ul.navList,
  ul.navListBottom {
    li {
      div.MuiListItemButton-root {
        height: 35px;
        padding: 0;
      }
      .MuiListItemText-root {
        span.MuiTypography-root {
          font-family: $font-family-Poppins;
          font-size: 23px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: 31px;
          letter-spacing: normal;
          color: $bs-strong-black-100;
        }
      }

      .MuiListItemIcon-root {
        @media screen and (max-width: 767.9px) {
          min-width: 38px !important;
        }
      }
    }
  }
  .logoutBtn {
    margin-top: 62px !important;
  }
  .MuiListItemButton-root:hover {
    background-color: transparent;
  }
  .navListBottom {
    padding: 0 24px;
    .logout-btn {
      width: 100%;
      padding: 0;
      justify-content: flex-start;
      gap: 16px;
      height: 48px;
      text-transform: none; 
      border-radius: 0;
      .logout-text {
        font-weight: 400;       
        color: $bs-strong-black-100;
        font-size: 23px;      
        line-height: 31px;
        font-family: $font-family-Poppins;
      }   
      &.Mui-focusVisible {
        background-color: rgba(0, 0, 0, 0.12);
      }    
    }
    &:hover {
      background: #ffc800;
    }
  }
}

.wideContainer
  .MuiDrawer-root.MuiDrawer-docked.drawerInactive
  .navList
  .MuiListItemIcon-root,
.wideContainer
  .MuiDrawer-root.MuiDrawer-docked.drawerInactive
  .navListBottom
  .MuiListItemIcon-root {
  @media screen and (min-width: 768px) {
    min-width: 50px !important;
  }
}
.wideContainer
  .MuiDrawer-root.MuiDrawer-docked.drawerActive
  .navList
  .MuiListItemIcon-root,
.wideContainer
  .MuiDrawer-root.MuiDrawer-docked.drawerActive
  .navListBottom
  .MuiListItemIcon-root {
  @media screen and (min-width: 768px) {
    min-width: 30px !important;
  }
}
.navDivider.MuiDivider-root.MuiDivider-fullWidth,
.logoutBtnDesk.MuiDivider-root.MuiDivider-fullWidth {
  margin: 48px 44px;
}
.edit {
  &:hover {
    text-decoration: none;
  }
}
.editCloseIcon {
  display: none;
}
.nameForm,
.businessForm,
.phone,
.phoneForm,
.addressForm,
.formMain,
.emailForm {
  label {
    font-family: $font-family-Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $bs-strong-black-100;
    margin-bottom: 4px;
    position: relative;
  }
  div {
    margin-top: 0px !important;
  }
}

.emailForm .rightDiv {
  label {
    &::after {
      content: "";
    }
  }
}
.emailForm .leftDiv {
  p {
    &:first-child {
      font-family: $font-family-Inter;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #646464;
      margin-bottom: 5px;
    }
  }
}
.address {
  p {
    &:first-child {
      font-family: $font-family-Inter;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #646464;
    }
  }
}
div.editAddress {
  margin-top: 24px !important;
  p {
    &:first-child {
      font-family: $font-family-Inter;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #646464;
    }
    &:last-child {
      font-family: $font-family-PoppinsSemiBold;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $bs-strong-black-100;
    }
  }
}
.wideContainer .accountSetting .required {
  margin-top: 4px;
}
.saveChangesBtn,
.stpButtons,
.mesgTechBtn {
  button.MuiButtonBase-root.MuiButton-root {
    @media screen and (max-width: 767.9px) {
      width: 100% !important;
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.wideContainer .userNameSideBar {
  @media screen and (min-width: 768px) {
    width: 225px;
    display: inline-block;
    margin-bottom: 16px;
  }
}
.main-container {
  .trackingStepperContainer {
    .stpButtons {
      gap: 24px;
      display: flex;
      @media screen and (max-width: 1023.9px) {        
        flex-direction: column-reverse;
        align-items: center;
      }
    }
  }
}
@media (hover: hover) {
  .wideContainer {
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.searchBox {
  .MuiInputBase-root.MuiOutlinedInput-root {
    height: 40px;
    border: solid 1px #7f7f7f;
    // padding: 0px 20px 0px 0px !important;
    padding: 0!important;
    border-radius: 0;
  }
  input.MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input {
    padding: 7px 12px;
    height: 20px;
    // margin-right: 35.7px;
    font-family: $font-family-Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $bs-strong-black-100;
    text-overflow: unset;
  }
  fieldset {
    display: none;
  }
}
div.MuiAutocomplete-popper {
  margin-top: 8px !important;
  ul {
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 $bs-strong-black-100;
    border: solid 1.5px #e8e8e8;
    background-color: #ffffff;
    li {
      &:first-child {
        /*margin-top: 20px;*/
      }
      &:last-child {
        border-style: none;
        /* margin-top: 20px;*/
      }
      font-family: $font-family-Inter;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $bs-strong-black-100;
    }
  }
}
.wideContainer .accountSetting .name {
  margin-top: 24px !important;
}
.wideContainer .accountSetting .phone {
  margin-top: 24px !important;
}
.wideContainer .accountSetting .passwordInfo .formMain {
  .MuiFormControl-root {
    margin: 0px 0 0px 0px;
    padding-bottom: 24px;
  }
}
.wideContainer .supportTools .cardMain {
  img {
    @media screen and (max-width: 767.9px) {
      width: 41px;
      height: 41px;
    }
  }
  p {
    @media screen and (max-width: 767.9px) {
      font-size: 14px !important;
    }
  }
}
.stpButtonsMain {
  @media screen and (max-width: 767.9px) {
    width: 100% !important;
  }
}
.dashboardFooter {
  border-top: 1px solid #bababa;
}

// .accountSetting {
//   .errorMsgFormat {
//     height: 30px;
//   }
// }

.searchBox input::placeholder {
  color: #646464;
  @media screen and (max-width: 1100px) {
    width: 144px;
  }
}

.requiredBox {
  margin-top: 24px !important;
}
.accountSetting {
  .userEmail {
    line-height: 3;
  }
}

td.timeField-deskonly {
  @media screen and (max-width: 1023.9px) {
    display: none;
  }
}
td span.timeField-mobile-and-tab {
  @media screen and (min-width: 1024px) {
    display: none;
  }
}
tr.appointmeHistoryRow {
  td {
    @media screen and (max-width: 1023.9px) {
      width: 33%;
      vertical-align: top;
      padding: 24px 0!important;
      font-size: 14px!important;
      line-height: 21px!important;
      span {
        font-size: 14px!important;
        line-height: 21px!important;
      }
    }
    &:first-child {
      @media screen and (max-width: 1023.9px) {
        display: flex;
        flex-direction: column;
        width: 100%;
      }      
    }    
  }
  &:first-child {
    td {
      @media screen and (max-width: 767.9px) { 
        padding-top: 47px!important;
      }
    }    
  } 
  .table-mid-col {
    text-align: center;
  }
  .table-status {
    text-align: right;
  }
}

.appContainer {
  .MuiTable-root {
    @media screen and (min-width:1280px) {
      width: 614px;
    }
  }
}

.drawerInactive {
  .appContainer {
    .MuiTable-root {
      @media screen and (min-width:1280px) {
        width: 808px;
      }
    }
  }
}

/*help and support*/
.helpSupport {
  .SearchIcon {
    background: url("../icon-nav-bar-search.svg") no-repeat left;
    width: 21px;
    height: 21px;
  }
  .MuiAutocomplete-root.Mui-focused {
    .searchBox .MuiInputBase-root.MuiOutlinedInput-root {
      border: solid 2px $bs-strong-black-100;
    }
    .SearchIcon {
      background: url("../icon-nav-bar-search-black.svg") no-repeat left;
      width: 21px;
      height: 21px;
    }
  }
}

/*help and support*/
.loginOptions {
  .MuiInputBase-root:after {
    border-style: none;
  }
}

.paymentMethodOptions,
.stateInput {
  .MuiInputBase-root:after {
    border-style: none;
  }
}

/*********Burry Drop Changes*******/
.burryDropNotificationBox,
.notificationBox {
  background-color: #000000;
  display: flex;
  flex-direction: row;
  margin-bottom: 40px !important;
  // padding: 16px 73px 19px 32px !important;
  .buttonText {
    color: #FFFFFF;
  }
}
.warnImg > img {
  width: 49px;
  height: 49px;
  margin: 16px 24px 16px 24px;
  border-radius: 50%;
}
.warnCloseIcon img {
  width: 21px;
  height: 21px; 
}
.warnCloseIcon .btnText { 
  margin: 16px 24px 0 10px;
}
.warnInfo {
  flex: 1;
  display: flex;
  align-items: center;
}
 .holidayBanner {
  .warnInfoContent p {
    display: inline-block;
    &:first-child {
      display: inline;
    }
  }
}
.warnInfo > .warnInfoContent {
  font-family: $font-family-Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  padding: 16px 11px 16px 0 !important;
}
.warnInfo > .warnInfoContent > p.MuiTypography-root {
  margin: 0;
  letter-spacing: normal;
}
.warnInfo > .warnInfoContent > u {
  white-space: pre !important;
  display: block !important;
}
.hideBDSN,
.hideNotification {
  display: none;
}
.paymentMethodBannerBox {
  padding: 0 !important;
}

/*Forgot Password OTP*/
.loginMain.loginOtp {
  .radioGroup {
    p {
      &:first-child {
        margin-bottom: 0px;
      }
    }
  }
}
/*Forgot Password OTP*/

.stpButtons {
  button {
    font-family: $font-family-Poppins;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: $bs-strong-black-100;

    &:hover {
      background: $bs-strong-black-100;
      color: #ffffff;
    }
  }
}
.statePlaceholder div {
  color: #646464;
  opacity: 0.5;
}
.addNewPaymentMethod {
  input::placeholder {
    color: #646464;
  }
  input::placeholder,
  input {
    font-family: $font-family-Inter;
    font-size: 16px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }
  input {
    color: #000000;
  }
  .creditCardInput,
  .bankAccNameInput,
  .cvvInput {
    .MuiInputBase-root.MuiInputBase-colorPrimary {
      border: 1px solid #000;
    }
    // .MuiInputBase-root.MuiInputBase-colorPrimary.Mui-focused {
    //   border: 1px solid #000000;
    // }
  }
  .addressOne,
  .addressTwo {
    width: 100%;
  }
  .expireCvvDate {
    .expirationDateInput,
    .cvvInput {
      width: 100%;
    }
  }
  .creditCardInput input,
  .cvvInput input {
    border-style: none !important;
  }
  .expireCvvDate {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    @media screen and (max-width: 767.9px) {
     flex-direction: column;
    }
  }

  .paymentMethodButtons button {
    @media screen and (min-width: 768px) and (max-width: 1023.9px) {
      margin: 0;
      padding: 0;
      width: 225px;
    }
    @media screen and (max-width: 767.9px) {
      margin: 0;
      &:last-child {
        margin-top: 20px;
      }
    }
  }
  .eyeToggleBtnWrap {
    left: -15px;
    position: relative;
  }
}
.cityZipcode {
  width: 630px;
  @media screen and (max-width: 1023.9px) and (min-width: 768px) {
    width: 376px;
  }
  @media screen and (min-width: 768px) {
    display: flex !important;
    align-items: baseline;
    gap: 24px;
  }
  @media screen and (max-width: 767.9px) {
    width: 100%;
    gap: 24px;
  }
  .cityInput {
    @media screen and (min-width: 768px) {
      width: 50%;
    }
  }
}
.stateZipCode {
  @media screen and (min-width: 768px) {
    display: flex;
    // align-items: center;
    width: 50%;
    gap: 20px;
  }
  @media screen and (max-width: 767.9px) {
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-top: 24px!important;
  }
  .stateInput {
    @media screen and (min-width: 768px) {
      width: 50%;
    }
  }
  .zipCodeInput {
    @media screen and (min-width: 768px) {
      width: 50%;
      position: relative;
    }
    .errorMsgFormat {
      @media screen and (min-width: 768px) {
        // position: absolute;
        // bottom: -20px;
      }
    }
  }
  .stateInput,
  .zipCodeInput {
    margin: 0;
    @media screen and (max-width: 767.9px) {
      margin: 0;
      width: 100%;
    }
  }
}
.editNewPaymentMethod {
  .expireCvvDate {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    .expirationDateInput,
    .cvvInput {
      width: 50%;
    }
  }
  .cityZipcode,
  .expireCvvDate {
    @media screen and (min-width: 1024px) {
      width: 470px;
    }
    @media screen and (max-width: 1023.9px) and (min-width: 768px) {
      width: 384px;
    }
  }
  .expireCvvDate {
    @media screen and (max-width: 767.9px) {
      display: flex;
    }
  }
  .checkboxMain.sameAddress {
    padding-left: 0px !important;
    font-family: $font-family-Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    input {
      margin-right: 12px;
    }
  }
}
.wideContainer {
  .editNewPaymentMethod {
    .paymentMethodButtons button {
      @media screen and (max-width: 767.9px) {
        width: 100% !important;
        margin: 0;
        &:last-child {
          margin-top: 20px;
        }
      }
    }
  }
}
.main-container.drawerActive {
  .paymentMethodButtons {
    @media screen and (min-width: 768px) and (max-width: 1023.9px) {
      display: flex;
      flex-direction: column;
    }
    button {
      @media screen and (min-width: 768px) and (max-width: 1023.9px) {
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
.loginHeaderMain {
  button {
    border-bottom: 6px solid #ffffff;
  }
  .loginHeaderBottom {
    height: auto;
    background-image: linear-gradient(90deg, #fa783c 10%, #ffc800 35%);
    background-size: 100% 6px;
    background-position: 0 100%;
    background-repeat: repeat-x;
    padding-bottom: 6px;
    background-color: white;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  }
  .headerBorderGradientImg {
    display: none;
  }
}
.serviceDetailsSub {
  margin-bottom: 24px !important;
}

/**************Change Plan ******************/
.cuurentPlanAccount {
  font-size: 14px !important;
  line-height: 21px !important;
  font-weight: 600 !important;
  color: $bs-strong-black-100;
}
label.cuurentPlanAccount {
  font-family:$font-family-Inter;   
}
.changemyplanlink {
  font-size: 14px !important;
  line-height: 21px !important;
  font-weight: 400 !important;
  font-family: $font-family-Inter;
  color: $bs-strong-black-100;
}
.planOptions {
  .title {
    font-size: 19px !important;
    font-weight: 600 !important;
    line-height: 27px !important;
    color: #000000;
  }
  .planName {
    font-family: $font-family-Poppins;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #000000 !important;
    margin-top: 40px;
  }
  .planDetails {
    font-family: $font-family-Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #7f7f7f;
    margin-top: 8px;
    letter-spacing: normal;
  }

  .callUsNumber {
    margin-top: 16px;
    text-decoration: underline;
    font-family: $font-family-Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    // margin-bottom: 40px !important;
  }
}

/*******Change plan Confirmation Model **********/
.shopModalPopUp {
  .MuiDialog-container .MuiDialog-paper {
    padding: 64px 40px 48px 40px;
    @media screen and (max-width: 767.9px) {
      padding: 64px 16px 48px 16px;
    }
    margin: 0px;
    position: relative;
    .shopModalCloseIcon {
      position: absolute;
      top: 24px;
      right: 24px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .confirmationModel {
      .modalTitle {
        font-family: $font-family-Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        color: #000000;
      }
      .modalDesc {
        font-family: $font-family-Inter;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin: 16px 0px 0px 0px;
      }
      .btnContainer {
        margin-top: 40px;
        button {
          background: #ffc800;
          border-radius: 24px;
          text-transform: none;
          @media screen and (max-width: 767.9px) {
            width: 100% !important;
          }
          &:hover {
            background: #000000;
            color: #ffffff;
          }
        }
        button,
        span {
          margin: 0px;
          width: 240px;
          height: 48px;
          font-size: 14px;
          font-family: $font-family-Poppins;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          color: $bs-strong-black-100;
          box-shadow: none;
        }
      }
      .multiBtn {
        gap: 24px;
        @media screen and (max-width: 767.9px) {
          display: flex;
          flex-direction: column-reverse;
        }
        button.primary {
          background: #ffc800;
          &:hover {
            background: $bs-strong-black-100;
            color: #ffffff;
          }
        }
        button.secondary {
          background: #fff;
          border: 2px solid $bs-strong-black-100;
          &:hover {
            background: $bs-strong-black-100;
            color: #ffffff;
          }
        }
      }
    }
  }
}
/************Plan Details ***************/
div.planDetails.MuiBox-root {
  background: none !important;
  padding: 0 !important;
  font-family: $font-family-Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 31px;
  color: #000000;

  div.planDiv.MuiBox-root {
    margin-bottom: 8px;
    font-size: 16px;
    font-family: $font-family-Poppins;
    &:last-child {
      margin-bottom: 0px;
    }
    .planName {
      line-height: 24px;
      margin-top: 0px;
    }
  }
  .currentPlanTitle {
    font-family: $font-family-Poppins;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 767.9px) {
  div.planDetails.MuiBox-root {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}
.broadbandLabelModal-Dialog.shopModalPopUp {
  .MuiDialog-container .MuiDialog-paper {
    padding: 64px 40px 48px 40px;
    @media screen and (max-width: 767.9px) {
      padding: 64px 16px 48px 16px;
      margin: 80px 0px 25px 0px !important;
    }
  
    margin: 80px 10px 25px 20px;
    border-radius: 24px;
    position: relative;
    .shopModalCloseIcon {
      position: absolute;
      top: 24px;
      right: 24px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .broadbandLabelModal {
      .dialogcontent {
        padding: 0px !important;
        overflow: hidden;
        border: none !important;
      }
      .modalTitle {
        font-family: $font-family-Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        color: #000000;
      }
      .modalDesc {
        font-family: $font-family-Inter;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin: 16px 0px 0px 0px;
      }
      .btnContainer {
        margin-top: 40px;
        button {
          background: #ffc800;
          border-radius: 24px;
          text-transform: none;
          @media screen and (max-width: 767.9px) {
            width: 100% !important;
          }
          &:hover {
            background: #000000;
            color: #ffffff;
          }
        }
        button {
          margin: 0px;
          width: 240px;
          height: 48px;
          font-size: 14px;
          font-family: $font-family-Poppins;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          color: $bs-strong-black-100;
          box-shadow: none;
        }
      }
      .multiBtn {
        gap: 24px;
        @media screen and (max-width: 767.9px) {
          display: flex;
          flex-direction: column-reverse;
        }
        button.primary {
          background: #ffc800;
          &:hover {
            background: $bs-strong-black-100;
            color: #ffffff;
          }
        }
        button.secondary {
          background: #fff;
          border: 2px solid $bs-strong-black-100;
          &:hover {
            background: $bs-strong-black-100;
            color: #ffffff;
          }
        }
      }
    }
  }
}

/***************Change my plan ****************/
.changemyplanlink {
  margin-top: 24px !important;
}
.serviceDetailsList {
  li {
    margin-top: 16px !important;
  }
}
// .serviceDetailsSub {
//   margin-bottom: 24px !important;
// }
/**************Vacation Suspend *****************/
.linkText {
  color: #fff !important;
}
.cancelVacationSuspendModel {
  .cancelVacationSuspendText {
    // align-self: center;
    margin: 50px 0 50px 0;
  }
}
.vacationButtonContainer {
  display: flex;
  margin-top: 40px !important;
  margin-bottom: 20px !important;
  @media screen and (max-width: 767.9px) {
    flex-direction: column-reverse !important;
  }
}
// .calendarContainer {
//   @media screen and (max-width: 769.9px) {
//     margin-top: 20px !important;
//   }
// }

.vsBtn_changeplan {
  display: flex;
  flex-direction: row;

  @media screen and (min-width: 768px) and (max-width: 1023.9px) {
    flex-direction: column-reverse;
    button.pauseServiceBtn.updatePause {
      margin-bottom: 16px;
    }
    button.pauseServiceBtn.cancelPause {
      margin-top: 0 !important;
    }
  }

  @media screen and (max-width: 767.9px) {
    flex-direction: column-reverse;
    button.pauseServiceBtn.updatePause {
      margin-bottom: 16px;
    }
    button.pauseServiceBtn.cancelPause {
      margin-top: 0 !important;
    }
  }
}
/*****************************************************************/
/**************Vacation Suspend *****************/
.linkText {
  color: #fff !important;
}
.cancelVacationSuspendModel,
.pauseServiceModel {
  .cancelVacationSuspendText,
  .proceedlabel {
    align-self: self-start;
    margin: 16px 0 0px 0;
  }
}
div.vacationButtonContainer {
  @media screen and (max-width: 767.9px) {
    flex-direction: column-reverse !important;
    button.MuiButtonBase-root {
      width: 100%;
    }
  }
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  margin-top: 40px !important;
  margin-bottom: 8px !important;

  button {
    padding: 0;
    margin: 0;
    font-family: $font-family-Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    &:hover {
      background: #000;
      color: #fff;
    }
  }
  button.Mui-disabled {
    background: #fff1bf;
    color: #000000;
  }
  @media screen and (max-width: 767.9px) {
    button.MuiButtonBase-root.MuiButton-root {
      width: 100% !important;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023.9px) {
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
  }
}
// .calendarContainer {
//   @media screen and (max-width: 769.9px) {
//     margin-top: 20px !important;
//   }
// }

/*******Pause Service *********/
/*******Pause Service *********/

.calendarContainer.vacationPauseServiceCalendar {
  width: 50% !important;
  .inputDate {
    width: 100% !important;
    // // border: 1px solid #000000 !important;
    border-radius: none !important;
  }
}

.pauseServiceDropdownBorder {
  width: 100% !important;
  border: 1px solid #646464 !important;
  border-radius: 1px !important;
  fieldset {
    border: 0 !important;
  }
  div {
    padding: 0px 0px !important;
    border-radius: 1px !important;
    font-family: $font-family-Poppins;
    font-weight: 500;
  }
}
.pauseServiceDropdownBorder div:focus-visible {
  border: thin solid black;
}

.vacationPauseServiceBtn {
  margin: 0.8rem !important;
}

.vsContainer > div.MuiBox-root {
  padding: 48px 24px 48px 24px !important;
  background: #ffffff;
  margin-top: 40px !important;
}

.vsContainer {
  .vacationSuspendPause {
    .title1,
    .title2 {
      font-family: $font-family-Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
    }
  }
  .pauseServiceTitle,
  .cancelServiceTitle {
    font-family: $font-family-PoppinsSemiBold;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    color: $bs-strong-black-100;
  }

  hr {
    margin: 16px 0px 32px 0px;
  }
  .subTitle {
    font-family: $font-family-Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    margin-bottom: 20px;
  }

  .pauseServiceLabel {
    label,
    p {
      font-family: $font-family-Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 4px;
    }
    .MuiInputBase-root.Mui-disabled {
      background-color: #F2F3F3;
      color: #646464;     
    }
    .Mui-disabled.MuiSelect-select {
      -webkit-text-fill-color: initial;
    }
  }
  .resumeServiceLabel {
    font-family: $font-family-Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 4px;
  }
  .calendarsContainer {
    gap: 24px !important;
    justify-content: flex-start !important;
    margin-top: 24px !important;
    @media screen and (min-width: 768px) and (max-width: 1023.9px) {
      width: 100% !important;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      div.calendarContainer {
        width: 100% !important;
      }
    }

    @media screen and (max-width: 767.9px) {
      width: 100% !important;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      div.calendarContainer {
        width: 100% !important;
      }
    }
  }
  .pauseServiceDropdownBorder {
    div {
      font-family: $font-family-Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      // line-height: 24px; ---> Day-503 --> changes
      color: #646464;
      display: flex;
      //padding: 8px 16px !important;
    }
    input {
      padding: 8px 0px 8px 16px;
    }
  }
  .pauseServiceDropdownBorder > div > .selectComponentInput {
    padding: 8px 16px !important;
  }

  .calendarMain {
    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          padding: 8px 0px 8px 16px;
          font-family: $font-family-Inter;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #646464;
        }
      }
    }
  }
  .vacationPauseServiceCalendar {
    .calendarMain {
      .react-datepicker-wrapper {
        .react-datepicker__input-container {
          input {
            padding-left: 50px !important;
          }
        }
      }
    }
  }

  .vacationPauseServiceCalendar > div > div > div > svg {
    // display: none;
    // background: url("../../assets/images/calenderSvg/calendar.svg") no-repeat
    //   right 12px center;
  }

  .requiredlabel {
    p {
      margin-top: 14px;
      font-family: $font-family-Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .submitAlertBox {
    display: table;
    margin: auto !important;
  }
  .submitAlert {
    background-color: #000000;
    color: #fff;
    text-align: center;
  }
  .nextBillDate {
    font-family: $font-family-InterBold;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .desc {
    p.descPara1,
    p.descPara2 {
      font-family: $font-family-Inter;
      margin-top: 16px;
      line-height: 1.5;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    p.descPara2 {
      margin-top: 8px;
    }
  }
  .vacationSuspendUpdate {
    .vacationButtonContainer {
      .update {
        white-space: nowrap;
      }
      .update.Mui-disabled {
        background: #fff1bf !important;
        color: #4d4d4d !important;
      }
    }
    p {
      font-family: $font-family-Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }
}
.pauseServiceModel {
  font-family: $font-family-Inter;
  font-size: 16px;
  color: #000000 !important;
}
.cancelVacationSuspendModel > p {
  font-family: $font-family-PoppinsSemiBold;
  font-weight: 500;
  line-height: 1.5;
  // margin-bottom: 5px;
}
.cancelServiceButton {
  margin-top: 24px !important;
  button {
    white-space: nowrap;
    margin: 0;
    padding: 0;
    font-family: $font-family-Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    &:hover {
      background: #000;
      color: #fff;
    }
  }
}

.cancelServiceDesc {
  p {
    font-family: $font-family-Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
}
.pauseServiceDiv {
  .planServiceBtn {
    margin-left: 0px !important;
  }
}

/*************Vacation Suspend model *****/
div.pauseServiceModel,
div.cancelVacationSuspendModel,
.userSuspendedModel {
  
  margin: 0;
  .modelTitle {
    font-family: $font-family-Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    color: #000000;
    align-self: baseline;
  }
  .modelDesc,
  .proceedlabel {    
    font-family: $font-family-Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;   
    align-self: baseline;     
  }
  .modelDesc {
    padding-top: 16px;
  }

  button.noBackBtn,
  button.yesBtn {
    margin-left: 0;
    display: block;
  }
}
/***********Vacation Model *********/
/*******Notification panel*****/
.notificationContainerMain {
  position: relative;
  .notificationContainer {
    top: -32px;
    box-shadow: -4px 4px 16px 2px rgba(75, 75, 75, 0.24);
    border-radius: 24px;

    @media screen and (max-width: 767.9px) {
      right: 16px;
    }
    a.linkText {
      color: #000000 !important;
    }
  }
}
.notificationBody {
  a.linkText {
    color: #000000 !important;
    text-decoration-color: #000000;
  }
}

// .react-datepicker__day--in-range {
//   background-color: #fff !important;
//   &:hover {
//     background-color: #ffc800 !important;
//   }
// }
// .react-datepicker__day {
//   &:hover {
//     background-color: #ffc800 !important;
//   }
// }
.vacationPauseServiceCalendar,.nonAuthflow {
  .calendarMain {
    div.react-datepicker {
      width: 411px;
      background: #ffffff;
      border: 1px solid #e8e8e8;
      box-shadow: 0px 4px 8px 4px rgba(75, 75, 75, 0.24);
      border-radius: 8px;
      padding: 24px 40px;
      position: relative;
      @media screen and (max-width: 1023.9px) and (min-width: 768px) {
        width: 376px;
      }
      @media screen and (max-width: 767.9px) {
        width: calc(100% - 8px);
      }
      div.react-datepicker__day {
        margin-right: 3px;
        @media screen and (max-width: 1023.9px) and (min-width: 768px) {
          margin-right: -3px;
        }
        @media screen and (max-width: 767.9px) {
          margin-right: 0px;
        }
      }
    }

    .react-datepicker__navigation {
      position: absolute;
      top: 26px;
    }
    .react-datepicker__navigation.react-datepicker__navigation--previous {
      @media screen and (min-width: 768px) {
        left: 35px;
      }
    }
    .react-datepicker__navigation.react-datepicker__navigation--next {
      right: 35px;
    }
  }
}

@media screen and (max-width: 1023.9px) and (min-width: 768px) {
  .cancelServiceButton {
    margin-top: 32px !important;
  }
}

@media screen and (max-width: 767.9px) {
  .cancelServiceButton {
    margin-top: 32px !important;
    button.MuiButtonBase-root.MuiButton-root {
      width: 100% !important;
    }
  }
}

div.pauseServiceModel,
div.cancelVacationSuspendModel,
div.userSuspendedModel {
  padding: 64px 40px 48px 40px;
  @media screen and (max-width: 767.9px) {
    padding-left: 16px;
    padding-right: 16px;
  }
  .popupBtn {
    display: flex;
    margin-top: 40px;
    @media screen and (max-width: 767.9px) {
      flex-direction: column-reverse;
      width: 100%;
      gap: 24px;
    }
    button.yesBtn {
      background-color: #ffc800;
      box-shadow: none;
    }
    button {
      height: 48px;
      width: 240px;
      margin: 0 24px 0 0;
      font-family: $font-family-Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      &:hover {
        background-color: #000000;
        color: #ffffff;
      }

      @media screen and (max-width: 767.9px) {
        width: 100%;
        margin: 0;
      }
    }
  }
}

#menu- > div.MuiPaper-root.MuiPaper-elevation {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 4px 8px 4px rgba(75, 75, 75, 0.24);
  border-radius: 8px;
}
.banner_here {
  // color: #ffffff;
  // &:hover {
  //   color: #ffffff;
  // }
}

/************RoadBore Banner ***********/
.roadBoreBanner_ques,
.roadBoreBanner_title_ans {
  display: block;
  font-size: 16px;
  font-family: $font-family-Inter;
  line-height: 24px;
}
.roadBoreBanner_ques {
  margin-bottom: 25px;
}
.roadBoreNotificationPanel {
  .roadBoreBanner_ques {
    margin-bottom: 10px;
  }
}

.waitMsg {
  width: auto;
  display: inline-block;
  color: #000000;
  text-align: left;
}
.hideWaitMsg {
  display: none;
}
div.pauseServiceDiv.MuiBox-root.css-0 > button.Mui-disabled {
  background: #fff1bf !important;
  color: #4d4d4d !important;
}
.QFUser_firstTimeResetPass > p {
  font-family: $font-family-Inter;
  color: $bs-strong-black-100;
  /* Body Text/Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: normal;
}
.QFUser_firstTimeResetPass > p:nth-child(2) {
  padding-top: 15px;
}
.myBills {
  button.MuiButtonBase-root.MuiIconButton-root {
    &:hover {
      background: none;
      color: unset;
    }
  }
}
.userSuspendedModel {
  margin: 0px !important;
  button.yesBtn {
    background-color: #ffc800;
    box-shadow: none;
  }
  .suspendedBtnDiv {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-top: 40px;
    button {
      height: 48px;
      width: 240px;
      margin: 0 0px 0 0;
      &:hover {
        background-color: #000000;
        color: #ffffff;
      }

      @media screen and (max-width: 767.9px) {
        width: 100%;
        margin: 0;
      }
    }
  }
}
.wideContainer .accountSetting {
  div.planOptions {
    button {
      height: 32px;
      width: 164px !important;
      min-width: 164px !important;
      padding: 14px 0px;
      margin-top: 16px !important;
      margin-left: 0px !important;
      // margin-bottom: 40px !important;
      font-style: normal;
      font-weight: 700 !important;
      font-size: 12px !important;
      line-height: 16px !important;
      color: #000000 !important;
      &:last-child {
        margin-left: 0px;
      }
      &:hover {
        background: $bs-strong-black-100;
        color: #ffffff !important;
      }
      &.voicePlanBtn {
        margin-bottom: 0!important;
      }
    }
  }
}
.wideContainer .accountSetting {
  .MuiTabs-root {
    .MuiTabs-flexContainer {
      gap: 64px;
    }
    button.MuiButtonBase-root {
      // font-family:$font-family-InterSemiBold!important;
      font-family: $font-family-Poppins;
      font-size: 14px !important;
      font-weight: 500 !important;
      font-stretch: normal;
      font-style: normal;
      line-height: 21px !important;
      letter-spacing: normal;
      color: $bs-strong-black-100;
      padding: 0;
      @media screen and (max-width: 767.9px) {
        font-size: 12px !important;
      }
      &:hover {
        // background: $bs-strong-black-100;
        color: #000000 !important;
      }
    }
  }
}

.wideContainer .paymentCard .header span.MuiButtonBase-root.MuiRadio-root {
  width: 21px;
  height: 21px;
  margin-right: 0px;
  padding: 0px;
  //margin-top: -2px;
  float: left;
  span {
    width: 21px;
    height: 21px;
    padding: 0px;
    margin-right: 0px;
  }
  + p {
    margin-left: 31px;
  }
}
// .wideContainer .paymentCard .header {
//   p {
//     margin-left: 31px;
//   }
// }

.paynowPaymentMethodsMain {
  .paynowPaymentMethods {
    width: 288px;
    height: 40px;
    .MuiInputBase-input {
      border: 1px solid $bs-strong-black-100;
      padding: 8px 16px;
      border-radius: 0px;
    }
  }
}
p.suspendedPayBalanceDue {
  font-family: $font-family-Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.paySuspendedDivider {
  margin: 16px 0px 32px 0px !important;
}

// .wideContainer button.MuiButtonBase-root.payNowButton {
//   &:hover {
//     background-color: #000;
//     color: #fff !important;
//   }
// }
div.vacationPauseModel {
  border-style: none;
}

// .networksettingbtns {
//   margin: 0 !important
// }
button.MuiButtonBase-root.networksettingbtns.MuiButton-root {
  &:hover {
    background-color: #000;
    color: #fff;
  }
  margin: 0 !important;
}
.wideContainer {
  .accountSetting {
    .network_name,
    .network_password {
      font-family: $font-family-Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      // margin-top: 32px !important;
    }
    .network_name {
      margin-top: 32px !important;
    }
    .network_name_value {
      font-family: $font-family-Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-top: 8px;
      margin-bottom: 16px !important;
    }
    .network_password_value {
      font-family: $font-family-Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      border: none;
      outline: none;
      margin-top: 8px;
      margin-bottom: 16px !important;
    }
  }
}
.restartModemSuccessModal {
  button.MuiButtonBase-root.networksettingbtns {
    font-family: $font-family-Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    width: 240px;
    height: 48px;
    box-shadow: none;
    margin-top: 40px !important;
    @media screen and (max-width: 767.9px) {
      width: 100%;
    }
  }
}
.restartModemModal,
.restartModemUnSuccessModal,
.restartModemSuccessModal {
  padding: 64px 40px 48px 40px;
  .title {
    font-family: $font-family-Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 16px;
  }
  .desc2 {
    margin-top: 24px;
  }
  .desc,
  p {
    font-family: $font-family-Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .lasttext {
    margin-top: 24px;
  }
  .footerBtns {
    gap: 24px;
    margin-top: 40px;
    align-items: center;
    flex-direction: row;
    @media screen and (max-width: 767.9px) {
      flex-direction: column-reverse;
    }
    button {
      font-family: $font-family-Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      width: 240px;
      height: 48px;
      box-shadow: none;
      @media screen and (max-width: 767.9px) {
        width: 100%;
      }
    }
    .save {
      background: #ffc800;
      box-shadow: none;
    }
  }
}
.changeNetworkNameModal,
.changeNetworkPasswordModal {
  padding: 64px 40px 48px 40px;
  @media screen and (max-width: 767.9px) {
    padding: 64px 16px 48px 16px;
  }
  .successtitle {
    font-family: $font-family-Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    margin-bottom: 16px;
  }
  .title {
    font-family: $font-family-Poppins;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 16px;
  }
  .sucessdesc {
    margin-top: 0px !important;
    p {
      font-family: $font-family-Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .descMain {
    margin-top: 32px;
    gap: 16px;
    ul {
      margin-bottom: 32px;
    }
  }
  .descMain,
  .descMain p {
    font-family: $font-family-Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: normal;
  }
  .formMain {
    label,
    input {
      font-family: $font-family-Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 4px;
    }
    .required {
      color: #7f7f7f;
      margin-top: 12px;
    }
    input {
      padding: 8px 12px;
    }
  }
  .footerBtns {
    gap: 24px;
    margin-top: 28px;
    align-items: center;
    flex-direction: row;
    @media screen and (max-width: 767.9px) {
      flex-direction: column-reverse;
    }
    button {
      font-family: $font-family-Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      width: 240px;
      height: 48px;
      @media screen and (max-width: 767.9px) {
        width: 100%;
      }
    }
    .save {
      background: #ffc800;
      box-shadow: none;
    }
  }
}
.changeNetworkNameSuccessModal {
  padding: 64px 40px 48px 40px;
  @media screen and (max-width: 767.9px) {
    padding: 64px 16px 48px 16px;
  }
  button.close {
    font-family: $font-family-Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    width: 240px;
    height: 48px;
    position: relative;
    background: #ffc800;
    box-shadow: none;
    margin: 40px 0px 0px 0px;
    top:0;
    right: 0;
    &:hover {
      background: #000000;
      color: #ffffff;
    }
    @media screen and (max-width: 767.9px) {
      width: 100%;
    }
  }
  .title {
    font-family: $font-family-Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
  }
  .desc {
    font-family: $font-family-Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 16px;
  }
}
.changeNetworkPasswordModal {
  .descMain {
    //margin-bottom: 32px;
  }
  button.close {
    font-family: $font-family-Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    width: 240px;
    height: 48px;
    position: relative;
    background: #ffc800;
    box-shadow: none;
    margin: 40px 0px 0px 0px;
    top:0;
    right: 0;
    &:hover {
      background: #000000;
      color: #ffffff;
    }
    @media screen and (max-width: 767.9px) {
      width: 100%;
    }
  }
}
.changeNetworkPasswordSecondModal {
  margin-top: 32px;
  .formMainContainer {
    gap: 20px;
    @media screen and (min-width:768px) {
      width: 460px;
    }
    .formMain {
      label,
      input {
        font-family: $font-family-Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 4px;
      }
    }
  }
  .required {
    color: #7f7f7f;
    margin-top: 12px;
    line-height: 24px;
  }
}

.wd-150 {
  width: 150px !important;
}

.wd-223 {
  width: 223px !important;
}

.Mui-disabled {
  &.networksettingbtns {
    &.save {
      background: #fff1bf !important;
      color: #4d4d4d !important;
    }
  }
}

.page-cta {
  display: flex;
  margin-top: 2rem !important;

  button {
    margin: 0 !important;
    padding: 0 !important;
    height: 3rem !important;
    font-size: 0.875rem;
  }
  button:first-child {
    margin-right: 1.5rem !important;
  }
  button:last-child {
    margin-left: 0 !important;
  }
  .cancel-button {
    background-color: #fff;
    border: 2px solid #000;
  }
}
.page-cta.paymentMethodButtons {
  button:last-child {
    margin-left: 0 !important;
  }
} 

@media screen and (max-width: 768px) {
  .page-cta {
    flex-direction: column-reverse;

    button:first-child {
      margin-right: 0;
      margin-top: 1.5rem !important;
    }
  }
}

@media screen and (max-width: 375px) {
  .page-cta {
    button {
      margin: 0;
      width: 100% !important;
    }
  }
}

/*New Login Header Changes*/
.loginHeader{
  .loginHeaderContainer{
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1023.9px) {
      position: fixed;
    }
    //position: fixed;
    width: 100%;
    z-index: 1000;
    top:0px;
    .topRow{
      min-height: 56px;
      @media screen and (max-width: 1023.9px) {
        min-height: 48px;
      }
      .main{
        .MuiBox-root{
          nav.MuiAppBar-root{
            position: relative;
           }
        }
        .MuiAppBar-root{
          background: #1F1F1F;
          box-shadow: none;
          height: 56px;
          min-height: 56px;
          @media screen and (max-width: 1023.9px) {
            height: 48px;
            min-height: 48px;
          }
          .MuiToolbar-root{
            min-height: 56px;
            @media screen and (max-width: 1023.9px) {             
              min-height: 48px;
            }
            position: relative;
            @media screen and (min-width: 1280px) {
              padding: 0px 100px;
           }
           @media screen and (max-width: 1279.9px) and (min-width: 1024px){
             padding: 0px 44px;
           }
           @media screen and (max-width: 1023.9px) and (min-width: 768px){
             padding: 0px 36px;
           }
           @media screen and (max-width: 767.9px) {
             padding: 0px 24px;
           }
           .topnav{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            @media screen and (min-width: 1440px) {
              max-width: 1240px;
              margin: 0 auto;
           }
            hr{
              background: #737373;
              width: 2px;
              border-style: none;
              opacity: unset;
              display: flex;
              margin: 0px 16px;
              height: auto;
            }
            a{
              margin-left: 0px;
              text-decoration: none;
              border-bottom: 2px solid #1F1F1F;
              font-family: $font-family-GraphikRegular;
              color: #ffffff;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 124%; /* 17.36px */
              letter-spacing: -0.28px;
              &:hover{
                border-bottom: 2px solid #FFFFFF;
              }
            }
            .topnavLeftLinks{
              display: flex;
              align-items: center;
              a{
                &:first-child{
                  border-bottom:2px solid #FFC800;
                }
              }
              hr{
                &:last-child{
                  display: none;
                }
              }
            }
            .topnavRightLinks{
              //margin-right: 32px;
              display: flex;
              align-items: center;
              .checkAvailability{
                margin-right: 32px;
                text-decoration: none;
                width: 200px;
                height: 40px;
                border-radius: 8px;
                border: 2px solid  #FCFCFC;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover{
                  background: #ffffff;
                  a{
                    color: #000000;
                  }
                }
                a{
                  width: 100%;
                  text-align: center;
                  border-style: none;
                  &:hover{
                    border-style: none;
                  }
                }
              }
              .topnavRightGroupLinks{
              display: flex;
              align-items: center;
              hr{
                &:last-child{
                  display: none;
                }
              }
              }
            }
            @media screen and (max-width: 1023.9px) {
              display: none;
           }
           }
           button{
            position: absolute;
            right: 24px;
            padding: 0;
            @media screen and (min-width: 768px) and (max-width: 1023.9px) {
              right: 36px;
           }
            @media screen and (min-width: 1024px) {
              display: none;
           }
            svg{
              color: #ffffff;
              @media screen and (min-width: 1024px) {
                display: none;
             }
            }
           }
          }
        }
      }
    }
    .bottomRow{
      min-height: 80px;
      position: relative;
      @media screen and (max-width: 1023.9px){
      display: none;
      }
      .main{
        .MuiBox-root{
          nav.MuiAppBar-root{
            position: relative;
            z-index: auto;
            box-shadow: 0px 2px 16px 0px rgba(31, 31, 31, 0.24);
            background-color: #FCFCFC;
           }
        }
       .MuiToolbar-root{
          background: #FCFCFC;
          box-shadow: none;
          height: 80px;
          min-height: 80px;
          position: relative;
          @media screen and (min-width: 1280px) {
            padding: 0px 100px;
         }
         @media screen and (max-width: 1279.9px) and (min-width: 1024px){
           padding: 0px 44px;
         }
         @media screen and (max-width: 1023.9px) and (min-width: 768px){
           padding: 0px 36px;
         }
         @media screen and (max-width: 767.9px) {
           padding: 0px 24px;
         }

         .bottomnav{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          @media screen and (min-width: 1440px) {
            max-width: 1240px;
            margin: 0 auto;
         }
          .bottomnavLeftLinks{
            display: flex;
            align-items: center;
          }
          .bottomCenterLinks{
            display: flex;
            align-items: center;
            gap: 32px;
            a{
              margin-left: 0px;
              text-decoration: none;
              border-bottom: 2px solid transparent;
              font-family: $font-family-GraphikRegular;
              color: #1F1F1F;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 124%; /* 17.36px */
              letter-spacing: -0.32px;
              &:hover{
                border-bottom: 2px solid #1F1F1F;
              }
              @media screen and (min-width:1024px) {
                margin-top:4px
              }
            }
            .supportSection{
              // .MuiInputBase-root.MuiOutlinedInput-root{
              //   border-style: none;
              //   .MuiSelect-select.MuiSelect-outlined{
              //     border-style: none;
              //   }
              // }
              button{
                color: #1F1F1F;
                background: none;
                border-style: none;
                padding: 0px;
                font-family: $font-family-GraphikRegular;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 124%; /* 19.84px */
                letter-spacing: -0.32px;
              }
              button[aria-expanded="false"]{
              background: url("../images/downArrow.png") no-repeat right;
              padding-right: 24px;
              background-position-y: -2px;
              }
              button[aria-expanded="true"]{
                background: url("../images/upArrow.png") no-repeat right;
                padding-right: 24px;
                background-position-y: -2px;
                border-bottom:2px solid #FFC800;
                }
            }
          }
          .bottomnavRightLinks{
            //margin-right: 32px;
            display: flex;
            align-items: center;
            button{
              color: #1F1F1F;
              background: none;
              border-style: none;
              padding: 0px;
              font-family: $font-family-GraphikRegular;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 124%; /* 19.84px */
              letter-spacing: -0.32px;
            }
            button[aria-expanded="false"]{
            background: url("../images/downArrow.png") no-repeat right;
            padding-right: 24px;
            background-position-y: -2px;
            border-bottom:2px solid #ffffff;
            margin-top: 5px;
            }
            button[aria-expanded="true"]{
              background: url("../images/upArrow.png") no-repeat right;
              padding-right: 24px;
              background-position-y: -2px;
              border-bottom:2px solid #FFC800;
              margin-top: 5px;
              }
            .checkAvailability{
              margin-right: 32px;
              text-decoration: none;
              width: 200px;
              height: 40px;
              border-radius: 8px;
              border: 2px solid  #FCFCFC;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover{
                background: #ffffff;
                a{
                  color: #000000;
                }
              }
              a{
                border-style: none;
                &:hover{
                  border-style: none;
                }
              }
            }
            .bottomnavRightGroupLinks{
            display: flex;
            align-items: center;
           
            }
          }
          }
        }
      }
    }
    }
    .bottomRow.stickyHeader{
      @media screen and (min-width: 1024px) {
      overflow: unset;
      }
      .main{
      @media screen and (min-width: 1024px) {
      position: fixed;
     width: 100%;
      top: 0;
        }
      }
    }
}
a.linkActive{
  border-bottom:2px solid #FFC800!important;
}
.base-Popup-root.base-Menu-root {
  &.profileDesktop {
  @media screen and (min-width: 1024px) {
   margin-left: -35px;
  }
  @media screen and (min-width: 1280px) {
     margin-left: -60px;
  }
}
&.supportDesktop {
  @media screen and (min-width: 1024px) {
   margin-left: -45px;
  }
}
  ul{
    margin: 5px;
    padding: 8px;
    right: 0px !important;
    background: #f3f3f4;
    border: none;
    border-radius: 8px 8px 8px 8px;
    -webkit-box-shadow: 4px 9pt 1pc 0 rgba(31,31,31,.16);
    box-shadow: 4px 9pt 1pc 0 rgba(31,31,31,.16);
    -ms-flex-direction: column;
    flex-direction: column;
    inset: auto !important;
    li.base-MenuItem-root{
      display: flex;
     
      list-style-type: none;
      //height: 40px;
      align-items: center;
      // &:first-child{
      //   border-top-left-radius: 8px;
      //   border-top-right-radius: 8px;
      // }
      &:last-child{
       a{

       }
      }
    &:hover{
        background: #1F1F1F;
        border-radius: 8px;
       a{
        color: #ffffff!important;
        }
      }
      a{
        padding: 16px 24px;
        font-family: GraphikRegular, Helvetica, sans-serif !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 124%;
        letter-spacing: -0.32px;
        text-align: left;
        text-decoration: none;
        color: #1F1F1F!important;
       
      }
    }
  }
}
.MuiDrawer-paper.MuiDrawer-paperAnchorRight{
  @media screen and (max-width: 1023.9px) and (min-width: 768px){
   width: 788px;
  }
  @media screen and (max-width: 767.9px) {
    width: 305px;
  }
}
/*New Login Header Changes - End*/
/*New Home Page Footer*/
.footerParent{
  background: #E6E7E8;
.simplifiedFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //border-top: 1px solid #979797;

  @media screen and (max-width: 767.9px){
      flex-direction: column!important;
      padding: 48px 16px 80px 16px;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 48px;
  }
  // @media screen and (max-width: 1279.9px) and (min-width: 1024px){
  //     padding: 48px 45px 48px 44px;
  // }
  // @media screen and (max-width: 1023.9px) and (min-width: 768px){
  //     padding: 48px 36px 48px 36px;
  // }
  // @media screen and (min-width: 1280px) and (max-width: 1439.9px){
  //     padding: 48px 44px 48px 44px;
  // }
  // @media screen and (min-width: 1440px) {
  //   padding: 48px 100px 48px 44px;
  //   max-width: 1544px;
  //   width: 100%;
  // }
  .footerleft{
      gap: 18px;
      display: flex;
      flex-direction: column;
      display: flex;
      // flex-direction: row;
      @media screen and (max-width: 767.9px){
          flex-direction: column-reverse;
          gap: 48px;
      }
      // div{
      //     cursor: pointer;
      // }
      //  a{
      //      &:hover{
      //          text-decoration: underline!important;
      //      }
      //  }
      .footerleftBottom{
          display: flex;
          align-items: center;
          justify-content: center;
          @media screen and (max-width: 767.9px){
            align-items: flex-start;
            gap: 24px;
          }
          hr{
            background: #BFBFBF;
            width: 2px;
            border-style: none;
            opacity: unset;
            display: flex;
            margin: 1px 8px;
            height: 15px;
            @media screen and (max-width: 767.9px) {
              display: none;
            }
            &:last-child{
              display: none;
            }
          }
          a{
            font-family: GraphikRegular, Helvetica, sans-serif !important;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 124%;
            letter-spacing: -0.28px;
            color: #1F1F1F;
            text-decoration: none;
            border-bottom: 2px solid transparent;
            cursor: pointer;
            &:hover{
            text-decoration: none;
            border-bottom: 2px solid #1F1F1F;
            }
          }
          @media screen and (max-width: 767.9px){
              flex-direction: column;
             // gap: 19px;
          }
      }
      .footerleftTop{
          display: flex;
          div{
              font-family: $font-family-GraphikRegular;
              cursor: default!important;
          }
      }
  }
  .footerleft{
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      //line-height: 1.5;
      line-height: 145%;
      letter-spacing: normal;
      color: #000;
     //  margin-right: 10px;
     // margin-left: 10px;
     font-family: $font-family-GraphikRegular;
  }
  .footerleft .footerleftBottom div{
    //   margin-right: 10px;
    //  margin-left: 10px; 
    hr{
      background: #737373;
    width: 2px;
    border-style: none;
    opacity: unset;
    display: flex;
    margin: 0px 16px;
    height: auto;
    }
     a{
      font-family: $font-family-GraphikRegular;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 145%;
      letter-spacing: normal;
      color: #000;
      font-weight: 600;
      text-decoration: none;
      border-bottom: 2px solid transparent;
      &:hover{
        text-decoration: none!important;
        border-bottom: 2px solid #1F1F1F;
      }
     }
     &:last-child{
      margin-right: 0px;
     }
     &:first-child{
      margin-left: 0px;
     }
     @media screen and (max-width: 767.9px){
      margin-right: 0px;
      margin-left: 0px; 
     }
  }
  // .footerleft .footerleftBottom div::after {
  //     height: 10px;
  //     content: "";
  //    // border: 1px solid #BFBFBF;
  //     display: inline-block;
  //     position: relative;
  //     left: 10px;
  //     top: 2px;
  //     @media screen and (max-width: 767.9px){
  //         border-style: none;
  //     }
  // }

  .footerleft .footerleftBottom >div:last-child::after {
      border-style: none;
  }

}
}
/*New Home Page Footer*/
.brspdMobileLogo{
  @media screen and (min-width: 1024px){
display: none;
  }
  position: absolute;
  top: 16px;
  z-index: 9999999;
  margin-left: 16px;
}
.menuIcon{
  background: url("../images/menu-mobile.png") no-repeat right;
  width: 32px;
  height: 32px;
}
.menuIcon.menuCloseIcon{
  background: url("../images/menu-mobile-close.png") no-repeat right;
  width: 32px;
  height: 32px;
}
.sidenavModal{
  @media screen and (min-width: 1024px){
    display: none;
      }
     z-index: 3;
    padding-top: 100px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(75, 75, 75, 0.80);
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.sidenav {
  background-color: #FCFCFC;
  position: absolute;
    top: 56px;
    width: 610px;
    right: 0px;
    @media screen and (max-width: 767.9px){
      width: 305px;
    }
}
.mobileTopRow{
  position: relative;
  height: 68px;
    padding: 16px;
    background: #1F1F1F;
    align-items: center;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 767.9px){
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 16px;
    }
    .topLinks{
      display: flex;
      flex-direction: row;
       hr{
        &:last-child{
          display: none;
        }
       }
    }
    @media screen and (max-width: 767.9px){
      //flex-direction: column;
      height: auto;
    }
    a{
    text-decoration: none;
    border-bottom: 2px solid #1F1F1F;
    font-family: GraphikRegular, Helvetica, sans-serif !important;
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 124%;
    letter-spacing: -0.28px;
    &:first-child{
      border-bottom: 2px solid #FFC800;
      &:hover{
        border-bottom: 2px solid #FFC800;
      }
    }
    &:hover{
      border-bottom: 2px solid #ffffff;
    }
    }
    hr {
      background: #737373;
      width: 2px;
      border-style: none;
      opacity: unset;
      display: flex;
      margin: 2px 16px;
      height: auto;
  }
  .checkAvailability {
    border-bottom-style: none;
    margin-left: 120px;
    text-decoration: none;
    width: 400px;
    height: 36px;
    border-radius: 8px;
    border: 2px solid #fcfcfc;
    //background: #FCFCFC;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
      background: #FCFCFC;
      a{
        color: #1F1F1F;
      }
    }
    //box-shadow: 2px 2px 8px 0px rgba(31, 31, 31, 0.24);
    @media screen and (max-width: 767.9px){
      width: 273px;
      margin-left: 0px;
      margin-top: 24px;
    }
    a{
      color: #ffffff;
      border-bottom-style: none;
      width: 100%;
      font-family: $font-family-GraphikRegular;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 124%; /* 17.36px */
      letter-spacing: -0.28px;
      text-align: center;
      &:hover{
        border-bottom-style:none;
      }
    }
    // &:hover{
    //   background: #1F1F1F;
    //   color: #FCFCFC;
    // }
}
}
.mobileMiddleRow{
  background: #FCFCFC;
  margin-top: 16px;
  .profileSectionMobile{
    .profileIcon{
    padding: 12px 24px;
    display: flex;
    align-items: center;
 
    cursor: pointer;
    span{
    font-family: $font-family-GraphikRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 17.6px */
    letter-spacing: -0.32px;
    margin-left: 8px;
    }
  }
  .profileIcon.open{
    background: url("../images/upArrow.png") no-repeat right;
    background-origin: content-box;
  }
  .profileIcon.closed{
    background: url("../images/downArrow.png") no-repeat right;
    background-origin: content-box;
  }
  .mobileLinks{
      padding:8px;
      background:#F3F3F4 ;
      .profileLogoSection{
        border-bottom: 1px solid #737373;
        display: flex;
        height: 39px;
        padding: 12px 24px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        span{
          color: #1F1F1F;
        font-family: $font-family-GraphikRegular;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 25.2px */
        letter-spacing: -0.18px;
        }
      }
      ul{
        list-style: none;
        margin: 0px;
        padding: 0px;
        li{
          display: flex;
          &:last-child{
            border-top:1px solid #737373;
            a{
            padding: 16px 24px 16px 24px;
            }
          }
          &:hover{
            background: #000;
            border-radius: 8px;
           a{
            color: #ffffff!important;
            }
          }
          a{
            padding: 16px 24px 16px 48px;
            color: #1F1F1F;
            text-decoration: none;
            font-family: $font-family-GraphikRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 124%; /* 17.36px */
            letter-spacing: -0.28px;
            &:hover{
              cursor: pointer;
            }
           
          }
        }
      }
  }
  }
  .mobileCenterLinks{
    .supportSection{
   // padding: 12px 24px;
    // display: flex;
    // align-items: center;
    cursor: pointer;
    width: 100%;
    .support{
      width: 100%;
      margin-left: 0px;
    padding: 16px 24px;
    text-decoration: none;
    font-family: GraphikRegular, Helvetica, sans-serif !important;
    color: #1F1F1F;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 124%;
    letter-spacing: -0.32px;
    cursor: pointer;
    span{
      font-family: GraphikRegular, Helvetica, sans-serif !important;
      color: #1F1F1F;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 124%;
      letter-spacing: -0.32px;
    }
    }
    .support.open{
      background: url("../images/upArrow.png") no-repeat right;
      background-origin: content-box;
    }
    .support.closed{
      background: url("../images/downArrow.png") no-repeat right;
      background-origin: content-box;
    }
    }
    ul.supportLinksMobile{
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    padding: 8px;
    background: #F3F3F4;
    li{
      padding: 16px 24px 16px 48px;
      a{
    color: #1F1F1F;
    padding: 0px;
    text-decoration: none;
    font-family: GraphikRegular, Helvetica, sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 124%;
    letter-spacing: -0.28px;
    border-bottom-style:none;
      }
      &:hover{
        background: #000;
        border-radius: 8px;
       a{
        color: #ffffff!important;
        }
      }
    }
    }
    display: flex;
    flex-direction: column;
    a{
    margin-left: 0px;
    padding: 16px 24px;
    text-decoration: none;
    border-bottom-style: none;
    font-family: GraphikRegular, Helvetica, sans-serif !important;
    color: #1F1F1F;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 124%;
    letter-spacing: -0.32px;
    cursor: pointer;
    // &:hover{
    //   background: #000;
    //   color: #ffffff;
    // }
    &:hover{
      background: #000;
      border-radius: 8px;
      color: #ffffff;
    }
    }
  }
}
.mobileBottomRow{
border-top:1px solid #737373;
display: flex;
flex-direction: column;
a{
  margin-left: 0px;
  padding: 16px 24px;
  text-decoration: none;
  font-family: GraphikRegular, Helvetica, sans-serif !important;
  color: #1F1F1F;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%;
  letter-spacing: -0.32px;
  cursor: pointer;
  border-bottom-style: none;
  &:hover{
    background: #000;
    border-radius: 8px;
    color: #ffffff;
  }
}
}
.profileLogoSectionDesktop{
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 0.5px solid #737373;
  span{
  font-family: $font-family-GraphikRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.16px;
  }
}
.profileDesktop{
  ul{
    li.base-MenuItem-root{
      &:last-child{
        a{
        padding: 16px 16px 12px 16px;
        }
        border-top: 0.5px solid #737373;
      }
    }
  }
}
@media screen and (max-width: 1023.9px){
body.menuModalOpen{
  overflow-y: hidden;
}
}
div.chacklinkActive{
  background: #FFC800!important;
  border:2px solid #FFC800!important;
  a{
    color: #1F1F1F!important;
  }
}
.footerLinkActive{
  width: fit-content;
  // text-decoration-color:#FFC800!important;
  // text-decoration-thickness: 2px;
 border-bottom:2px solid #FFC800!important;
}

#extole_zone_web_account_management_banner a {
  color: #ffffff;
  // text-decoration: none
}
#extole_zone_web_account_management_holidayReferallBanner div a {
  color: #ffffff;
  // text-decoration: none
}

#extole_zone_web_account_management_notification a{
  color: #000;
}

/*New Footer Changes*/
.fullFooter{
  // margin-top: 96px;
  background: #E6E7E8;
  @media screen and (min-width: 1280px) {
     padding: 80px 100px;
  }
  @media screen and (max-width: 1279.9px) and (min-width: 1024px){
    padding: 80px 44px;
  }
  @media screen and (max-width: 1023.9px) and (min-width: 768px){
    padding: 80px 36px;
  }
  @media screen and (max-width: 767.9px) {
    // margin-top: 80px;
    padding: 80px 24px;
  }
  .footerContainer{
    @media screen and (min-width: 1440px) {
      max-width: 1240px;
      margin: 0 auto;
      position: relative;
      width: 100%;
  }
    .topRow{
    padding-bottom: 40px;
      .main{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .socialIcons{
          display: flex;
          gap: 24px;
          align-items: center;
          @media screen and (max-width: 767.9px) {
            gap: 28px;
          }
          div,h4{
            margin-right: 8px;
            font-family: $font-family-GraphikRegular;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; 
            letter-spacing: -0.18px;
          }
        }
        .brspdLogo {
          @media screen and (max-width: 767.9px) {
          display: none;
          }
        }
      }
    }
    .midRow{
    border-top: 1px solid #BFBFBF;
    padding-top: 40px;
    @media screen and (max-width: 767.9px) {
      padding-top: 38px;
     }
    .main{
      display: flex;
      gap: 24px;
      flex-direction: row;
      justify-content: space-between;
     // align-items: center;
     @media screen and (max-width: 767.9px) {
       flex-direction: column;
       gap: 43px;
      }
      .column1,.column2,.column3{
        width: 100%;
        .main{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
        }
      }
      .column2,.column3{
        span,h3{
          font-family: $font-family-GraphikBold;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 115%; /* 27.6px */
          letter-spacing: -0.72px;
        }
        .list{
          margin-top: 24px;          
          display: flex;
          gap: 21px;
          @media screen and (min-width:768px) {
            margin-top: 25px;
            gap: 21px;
          }
          span,a{
            font-family:$font-family-GraphikRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 124%; /* 17.36px */
            letter-spacing: -0.28px;
            color: #1F1F1F;
              text-decoration: none;
              width: fit-content;
             border-bottom: 2px solid transparent;
              &:hover{
               // text-decoration: underline;
               border-bottom: 2px solid #1F1F1F;
              }
          }
        }
      }
      .column2{
        .list {
          @media screen and (max-width: 767.9px) {
            margin-top: 22px !important;           
          }
        }
      }
      .column1{
        .main{
          padding-right: 104px;
          @media screen and (max-width: 1279.9px) and (min-width: 1024px) {
            padding-right: 55px;
          }
          @media screen and (max-width: 1023.9px) and (min-width: 768px) {
            padding-right: 24px;
          }
          @media screen and (min-width:768px) {
            height: 100%;
          }
          @media screen and (max-width: 767.9px) {
            padding-right: 0;
          }
          .list{
            border-right: 1px solid #BFBFBF;
            display: flex;
            flex-direction: column;
            gap: 45px;
            @media screen and (max-width: 767.9px) {
              border-bottom: 1px solid #BFBFBF;
              border-right-style:none;
              padding-bottom: 48px;
              gap: 50px;
             }
             @media screen and (min-width:768px) {
              height: inherit;
              justify-content: space-between;
              margin-top:-4px;
            }
            a,span{
              font-family: $font-family-GraphikRegular;
              font-size: 22px;
              font-style: normal;
              font-weight: 600;
              line-height: 124%; /* 27.28px */
              letter-spacing: -0.44px;
              color: #1F1F1F;
              text-decoration: none;
              width: fit-content;
              border-bottom:2px solid transparent;
              &:hover{
               // text-decoration: underline;
               width: fit-content;
               border-bottom:2px solid #1F1F1F;
              }
            }
          }
        }
      }
      .list{
        display: flex;
        flex-direction: column;
        width: 100%;
        span{
          display: none;
        }
      }
    }
    }
    .bottomRow{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 56px;
      @media screen and (max-width: 1439.9px) and (min-width: 768px) {
        padding-top: 40px;
      }
      @media screen and (max-width: 767.9px) {
        padding-top: 50px;
        flex-direction: column;
        align-items: flex-start;
      }
      .leftRow{
       display: flex;
      align-items: center;
      @media screen and (max-width: 1439.9px) and (min-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 19px;
      }
      @media screen and (min-width: 1440px) and (max-width:2560px) {
        gap: 8px;
        margin-top: 13px;
      }
      @media screen and (max-width: 767.9px) {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-bottom:38px;
      }
      span.copyText{
        font-family: $font-family-GraphikRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 145%;
        color: #1F1F1F;
        letter-spacing: 0.04px;
      }
      @media screen and (min-width: 1440px) and (max-width:2560px) {
      hr.large-deviceHr {
        display: block;
        background: #BFBFBF;
        width: 2px;
        border-style: none;
        opacity: unset;
        display: flex;
        height: auto;
       }
      }
      .links{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        @media screen and (max-width: 767.9px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 21px;
          margin-bottom: 54px;
        }
        a{
          font-family:$font-family-GraphikRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 124%; /* 17.36px */
            color: #1F1F1F;
            text-decoration: none;
           // border-left: 1px solid #BFBFBF;
            // padding-left: 10px;
            // padding-right: 10px;
            //width: fit-content;
            border-bottom: 2px solid transparent;
              &:hover{
                //text-decoration: underline;
                border-bottom: 2px solid #1F1F1F;
              }
              @media screen and (max-width: 1279px) and (min-width: 768px) {
                &:first-child{
                  border-left-style: none;
                  padding-left: 0px;
                }
              }
              @media screen and (max-width: 767.9px) {
                border-left-style: none;
                padding-left: 0px;
                padding-right: 0px;
              }
        }
        hr{
          background: #BFBFBF;
          width: 2px;
          border-style: none;
          opacity: unset;
          display: flex;
          height: auto;
          @media screen and (max-width: 767.9px) {
            display: none;
          }
          &:last-child{
            display: none;
          }
        }
      }
    }
    .rightRow{
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }
  }
}
/*New Footer Changes - End*/

/*New Home Page Footer*/
.footerParent{
  background: #E6E7E8;
.simplifiedFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //border-top: 1px solid #979797;

  @media screen and (max-width: 767.9px){
      flex-direction: column!important;
      padding: 48px 16px 80px 16px;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 48px;
  }
  @media screen and (max-width: 1279.9px) and (min-width: 1024px){
      padding: 48px 44px;
  }
  @media screen and (max-width: 1023.9px) and (min-width: 768px){
      padding: 48px 36px 48px 36px;
  }
  @media screen and (min-width: 1280px){
    padding: 48px 100px 48px 100px;
  }
  @media screen and (min-width: 1440px) {
    padding: 48px 100px 48px 44px;
    max-width: 1544px;
    width: 100%;
  }
  .footerleft{
      gap: 18px;
      display: flex;
      flex-direction: column;
      display: flex;
      // flex-direction: row;
      @media screen and (max-width: 767.9px){
          flex-direction: column-reverse;
          gap: 48px;
      }
      // div{
      //     cursor: pointer;
      // }
      //  a{
      //      &:hover{
      //          text-decoration: underline!important;
      //      }
      //  }
      .footerleftBottom{
          display: flex;
          align-items: center;
          justify-content: center;
          @media screen and (max-width: 767.9px){
            align-items: flex-start;
            gap: 24px;
          }
          hr{
            background: #BFBFBF;
            width: 2px;
            border-style: none;
            opacity: unset;
            display: flex;
            margin: 1px 8px;
            height: 15px;
            @media screen and (max-width: 767.9px) {
              display: none;
            }
            &:last-child{
              display: none;
            }
          }
          a{
            font-family: GraphikRegular, Helvetica, sans-serif !important;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 124%;
            letter-spacing: -0.28px;
            color: #1F1F1F;
            text-decoration: none;
            border-bottom: 2px solid transparent;
            cursor: pointer;
            &:hover{
            text-decoration: none;
            border-bottom: 2px solid #1F1F1F;
            }
          }
          @media screen and (max-width: 767.9px){
              flex-direction: column;
             // gap: 19px;
          }
      }
      .footerleftTop{
          display: flex;
          div{
              font-family: $font-family-GraphikRegular;
              cursor: default!important;
          }
      }
  }
  .footerleft{
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      //line-height: 1.5;
      line-height: 145%;
      letter-spacing: normal;
      color: #000;
     //  margin-right: 10px;
     // margin-left: 10px;
     font-family: $font-family-GraphikRegular;
  }
  .footerleft .footerleftBottom div{
    //   margin-right: 10px;
    //  margin-left: 10px; 
    hr{
      background: #737373;
    width: 2px;
    border-style: none;
    opacity: unset;
    display: flex;
    margin: 0px 16px;
    height: auto;
    }
     a{
      font-family: $font-family-GraphikRegular;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 145%;
      letter-spacing: normal;
      color: #000;
      font-weight: 600;
      text-decoration: none;
      border-bottom: 2px solid transparent;
      &:hover{
        text-decoration: none!important;
        border-bottom: 2px solid #1F1F1F;
      }
     }
     &:last-child{
      margin-right: 0px;
     }
     &:first-child{
      margin-left: 0px;
     }
     @media screen and (max-width: 767.9px){
      margin-right: 0px;
      margin-left: 0px; 
     }
  }
  // .footerleft .footerleftBottom div::after {
  //     height: 10px;
  //     content: "";
  //    // border: 1px solid #BFBFBF;
  //     display: inline-block;
  //     position: relative;
  //     left: 10px;
  //     top: 2px;
  //     @media screen and (max-width: 767.9px){
  //         border-style: none;
  //     }
  // }

  .footerleft .footerleftBottom >div:last-child::after {
      border-style: none;
  }

}
}
/*New Home Page Footer*/
.brspdMobileLogo{
  @media screen and (min-width: 1024px){
display: none;
  }
  position: absolute;
  top: 13px;
  z-index: 9999999;
  margin-left: 24px;
  @media screen and (min-width: 768px) and (max-width:1023.9px){
    margin-left: 36px;
  }
}
.menuIcon{
  background: url("../images/menu-mobile.png") no-repeat right;
  width: 32px;
  height: 32px;
}
.menuIcon.menuCloseIcon{
  background: url("../images/menu-mobile-close.png") no-repeat right;
  width: 32px;
  height: 32px;
}
.sidenavModal{
  @media screen and (min-width: 1024px){
    display: none;
      }
     z-index: 3;
    padding-top: 100px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(75, 75, 75, 0.80);
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.sidenav {
  background-color: #FCFCFC;
  position: absolute;
    top: 48px;
    width: 610px;
    right: 0px;
    padding-bottom: 88px;
    @media screen and (max-width: 767.9px){
      width: 305px;
      padding-bottom: 64px;
    }
}
.mobileTopRow{
  position: relative;
  height: 68px;
    padding: 16px;
    background: #1F1F1F;
    align-items: center;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 767.9px){
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 16px;
    }
    .topLinks{
      display: flex;
      flex-direction: row;
       hr{
        &:last-child{
          display: none;
        }
       }
    }
    @media screen and (max-width: 767.9px){
      //flex-direction: column;
      height: auto;
    }
    a{
    text-decoration: none;
    border-bottom: 2px solid #1F1F1F;
    font-family: GraphikRegular, Helvetica, sans-serif !important;
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 124%;
    letter-spacing: -0.28px;
    &:first-child{
      border-bottom: 2px solid #FFC800;
      &:hover{
        border-bottom: 2px solid #FFC800;
      }
    }
    &:hover{
      border-bottom: 2px solid #ffffff;
    }
    }
    hr {
      background: #737373;
      width: 2px;
      border-style: none;
      opacity: unset;
      display: flex;
      margin: 2px 16px;
      height: auto;
  }
  .checkAvailability {
    border-bottom-style: none;
    margin-left: 120px;
    text-decoration: none;
    width: 400px;
    height: 36px;
    border-radius: 8px;
    border: 2px solid #fcfcfc;
    //background: #FCFCFC;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
      background: #FCFCFC;
      a{
        color: #1F1F1F;
      }
    }
    //box-shadow: 2px 2px 8px 0px rgba(31, 31, 31, 0.24);
    @media screen and (max-width: 767.9px){
      width: 273px;
      margin-left: 0px;
      margin-top: 24px;
    }
    a{
      color: #ffffff;
      border-bottom-style: none;
      width: 100%;
      font-family: $font-family-GraphikRegular;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 124%; /* 17.36px */
      letter-spacing: -0.28px;
      text-align: center;
      &:hover{
        border-bottom-style:none;
      }
    }
    // &:hover{
    //   background: #1F1F1F;
    //   color: #FCFCFC;
    // }
}
}
.mobileMiddleRow{
  background: #FCFCFC;
  margin-top: 16px;
  .profileSectionMobile{
    .profileIcon{
    padding: 12px 24px;
    display: flex;
    align-items: center;
 
    cursor: pointer;
    span{
    font-family: $font-family-GraphikRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 17.6px */
    letter-spacing: -0.32px;
    margin-left: 8px;
    }
  }
  .profileIcon.open{
    background: url("../images/upArrow.png") no-repeat right;
    background-origin: content-box;
  }
  .profileIcon.closed{
    background: url("../images/downArrow.png") no-repeat right;
    background-origin: content-box;
  }
  .mobileLinks{
      padding:8px;
      background:#F3F3F4 ;
      .profileLogoSection{
        border-bottom: 1px solid #737373;
        display: flex;
        height: 39px;
        padding: 12px 24px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        span{
          color: #1F1F1F;
        font-family: $font-family-GraphikRegular;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 25.2px */
        letter-spacing: -0.18px;
        }
      }
      ul{
        list-style: none;
        margin: 0px;
        padding: 0px;
        li{
          display: flex;
          &:last-child{
            border-top:1px solid #737373;
            a{
            padding: 16px 24px 16px 24px;
            }
          }
          &:hover{
            background: #000;
            border-radius: 8px;
           a{
            color: #ffffff!important;
            }
          }
          a{
            padding: 16px 24px 16px 48px;
            color: #1F1F1F;
            text-decoration: none;
            font-family: $font-family-GraphikRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 124%; /* 17.36px */
            letter-spacing: -0.28px;
            &:hover{
              cursor: pointer;
            }
           
          }
        }
      }
  }
  }
  .mobileCenterLinks{
    .supportSection{
   // padding: 12px 24px;
    // display: flex;
    // align-items: center;
    cursor: pointer;
    width: 100%;
    .support{
      width: 100%;
      margin-left: 0px;
    padding: 16px 24px;
    text-decoration: none;
    font-family: GraphikRegular, Helvetica, sans-serif !important;
    color: #1F1F1F;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 124%;
    letter-spacing: -0.32px;
    cursor: pointer;
    span{
      font-family: GraphikRegular, Helvetica, sans-serif !important;
      color: #1F1F1F;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 124%;
      letter-spacing: -0.32px;
    }
    }
    .support.open{
      background: url("../images/upArrow.png") no-repeat right;
      background-origin: content-box;
    }
    .support.closed{
      background: url("../images/downArrow.png") no-repeat right;
      background-origin: content-box;
    }
    }
    ul.supportLinksMobile{
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    padding: 8px;
    background: #F3F3F4;
    li{
      padding: 16px 24px 16px 48px;
      a{
    color: #1F1F1F;
    padding: 0px;
    text-decoration: none;
    font-family: GraphikRegular, Helvetica, sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 124%;
    letter-spacing: -0.28px;
    border-bottom-style:none;
      }
      &:hover{
        background: #000;
        border-radius: 8px;
       a{
        color: #ffffff!important;
        }
      }
    }
    }
    display: flex;
    flex-direction: column;
    a{
    margin-left: 0px;
    padding: 16px 24px;
    text-decoration: none;
    border-bottom-style: none;
    font-family: GraphikRegular, Helvetica, sans-serif !important;
    color: #1F1F1F;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 124%;
    letter-spacing: -0.32px;
    cursor: pointer;
    // &:hover{
    //   background: #000;
    //   color: #ffffff;
    // }
    &:hover{
      background: #000;
      border-radius: 8px;
      color: #ffffff;
    }
    }
  }
}
.mobileBottomRow{
border-top:1px solid #737373;
display: flex;
flex-direction: column;
a{
  margin-left: 0px;
  padding: 16px 24px;
  text-decoration: none;
  font-family: GraphikRegular, Helvetica, sans-serif !important;
  color: #1F1F1F;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%;
  letter-spacing: -0.32px;
  cursor: pointer;
  border-bottom-style: none;
  &:hover{
    background: #000;
    border-radius: 8px;
    color: #ffffff;
  }
}
}
.profileLogoSectionDesktop{
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 0.5px solid #737373;
  span{
  font-family: $font-family-GraphikRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.16px;
  }
}
.profileDesktop{
  ul{
    li.base-MenuItem-root{
      &:last-child{
        a{
        padding: 16px 16px 12px 16px;
        }
        border-top: 0.5px solid #737373;
      }
    }
  }
}
@media screen and (max-width: 1023.9px){
body.menuModalOpen{
  overflow-y: hidden;
}
}
div.chacklinkActive{
  background: #FFC800!important;
  border:2px solid #FFC800!important;
  a{
    color: #1F1F1F!important;
  }
}
.footerLinkActive{
  width: fit-content;
  // text-decoration-color:#FFC800!important;
  // text-decoration-thickness: 2px;
 border-bottom:2px solid #FFC800!important;
}

#extole_zone_web_account_management_banner a {
  color: #ffffff;
  // text-decoration: none
}

#extole_zone_web_account_management_notification a{
  color: #000;
}


#networkSettings{
  background-color: #fff;
  padding: 48px 24px;
}

.MybillPayments #mybill-payment-options{
  padding: 32px 0 40px 0;
}
.btnContainer {
  >a.startNewPlan {
      margin-top: 16px !important;
  }
}
  .paymentspanel > div.MuiBox-root div.planOptions.disconnectedUser.MuiBox-root {
    padding: 48px 24px 48px 24px;
    @media screen and (max-width: 767.9px) {
      padding: 48px 16px;
    }
  }

  .warnImg.ctlpaypalwarning{
    display: flex;
    @media screen and (min-width: 1024px){
      align-items: center;
    }
  }

  .warnInfo > .ctlpaypalcontent{
    padding: 16px 23px 16px 0 !important;
  }

  .visually-hidden-focusable:active,.visually-hidden-focusable:focus{  position: absolute;
    z-index: 999999999;
    top: 2%;
    background: #fff;
    left: 30%;
    border-radius: 5px;
    padding: 5px;}
    
.modelButton,.helpButton {
  background: none;
  border: 0;
  padding: 0;
}

.hiddenReader {
  display: none;
}

.notificationIcon button:focus {
  outline: border 0.0625rem #7f7f7f;
  border-radius: 25%; 
}

.loginMain input[type="email"]:focus,
.loginMain input[type="password"]:focus {
  outline: solid 0.1px $bs-strong-black-100;
  border-radius: 0.5px;
}
.loginMain .showErrorBorder input[type="email"]:focus,
.loginMain .showErrorBorder input[type="password"]:focus {
  outline: solid 0.1px #d11e00 !important; 
}

.header-wrapper {
  .my-account-btn {
    background: none;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    padding: 0;
  }
}

.buttonText{
  background:none;
  color: #000;
  border: 0px;
  text-decoration: underline;
  padding: 0;
}

.buttonLinks {
  border: 0px;
  background: none;
  text-decoration: underline;
  font-family: $font-family-Inter;
  span:first-child {
    margin: 0px;
  }
}

.buttonLinks:hover {
  text-decoration: none;
}

.mar0 {
  margin: 0px;
  padding: 0px;
}

button.slideBtn {
  background: none;
  border: 0px;
}
// select focus
// .SelectPaymentGeneralCard .dropdownPayment.selected .MuiSelect-select:focus {
//   outline: solid 0.1px $bs-strong-black-100;
//   border-radius: 0.5px;
// }

.base-Popup-root.base-Menu-root ul li.base-MenuItem-root a {
  display: block;
  width: 100%;
}

// .mainFields .css-n2fbdf-MuiInputBase-root-MuiInput-root-MuiSelect-root .MuiInputBase-input:focus,
// .SelectPayment .MuiInputBase-input:focus,
// .managePayMainContent .MuiInputBase-input:focus {
//   outline: solid 0.1px $bs-strong-black-100;
//   border-radius: 0.5px;
// }

// .wideContainer .pInfoEditMode .css-unujqm-MuiInputBase-root-MuiInput-root-MuiSelect-root .MuiInputBase-input:focus {
//   outline: solid 0.1px $bs-strong-black-100;
//   border-radius: 0.5px;

// }

// .inputNormalBorder .MuiInputBase-input:focus {
//   // outline: solid 0.1px $bs-strong-black-100;
//   border-radius: 0.5px;
//   border: solid 0.1px $bs-strong-black-100;
// }
.header-wrapper .css-78trlr-MuiButtonBase-root-MuiIconButton-root:focus {
  background-color: rgba(0, 0, 0, 0.04);
}
.dropdownMultiAccounts .multi-account-select .MuiSelect-select:focus {
  outline: solid 0.1px $bs-strong-black-100;
  border-radius: 0.5px;
}
.marbot24{margin-bottom: 24px !important;}
