
@font-face {
  font-family: Poppins;
  src: url('../fonts/Poppins-Regular.woff') format("woff");
}

@font-face {
  font-family: PoppinsBold;
  src: url('../fonts/Poppins-Bold.woff') format("woff");
}

@font-face {
  font-family: PoppinsMedium;
  src: url('../fonts/Poppins-Medium.woff') format("woff");
}

@font-face {
  font-family: PoppinsSemiBold;
  src: url('../fonts/Poppins-SemiBold.woff') format("woff");
}

@font-face {
  font-family: Inter;
  src: url('../fonts/Inter-Regular.woff') format("woff");
}

@font-face {
  font-family: InterMedium;
  src: url('../fonts/Inter-Medium.woff') format("woff");
}
@font-face {
  font-family: InterSemiBold;
  src: url('../fonts/Inter-SemiBold.woff') format("woff");
}

@font-face {
  font-family: InterBlack;
  src: url('../fonts/Inter-Black.woff') format("woff");
}

@font-face {
  font-family: InterBold;
  src: url('../fonts/Inter-Bold.woff') format("woff");
}

@font-face {
  font-family: GraphikRegular;
  src: url('../fonts/GraphikRegular.woff2') format("woff2");
}
@font-face {
  font-family: GraphikBold;
  src: url('../fonts/GraphikBold.woff2') format("woff2");
}


@font-face {
  font-family: HelveticaRegular;
  src: url('../fonts/Helvetica-Regular.woff2') format("woff");
}
@font-face {
  font-family: HelveticaBlack;
  src: url('../fonts/Helvetica-Black.woff2') format("woff");
}

$font-family-Poppins:Poppins,Helvetica,sans-serif!important;
$font-family-PoppinsSemiBold:PoppinsSemiBold,Helvetica,sans-serif!important;
$font-family-PoppinsBold:PoppinsBold,Helvetica,sans-serif!important;
$font-family-Inter:Inter,Helvetica,sans-serif!important;
$font-family-InterBold:InterBold,Helvetica,sans-serif!important;
$font-family-InterSemiBold:InterSemiBold,Helvetica,sans-serif!important;
$font-family-InterMedium:InterMedium,Helvetica,sans-serif!important;
/* Graphik Font Family */
$font-family-GraphikRegular: GraphikRegular, Helvetica, sans-serif !important;
$font-family-GraphikBold: GraphikBold, Helvetica, sans-serif !important;

/* Helvetica  Font Family */
$font-family-HelveticaRegular: HelveticaRegular,Poppins,sans-serif!important;
$font-family-HelveticaBlack: HelveticaBlack,Poppins,sans-serif!important;
body {
  font-family:$font-family-Poppins;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

html,
h1,
h2,
h3,
h4,
h5,
h6{
  font-family:$font-family-Poppins;
}

p, span {
  font-family:$font-family-Inter;   
}

a {
  color: #000000;
  &:hover {
    color: inherit;
  }
}

button{
  text-transform: none;
}

.react-datepicker-wrapper {
  width: 470px !important;
 // background: url("/Images/icon-arrow-down-black.svg") no-repeat  right 12px center !important;
  //background-size: 20px !important;
  //background-position: 5px !important;
  @media screen and (max-width: 767.9px) {
    width: 100%!important;
   }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 1.3rem !important;
  background-color: #ffc800 !important;
  color: #000000 !important;
  //background: none !important;
}

.react-datepicker__day {
  border-radius: 50% !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__header {
  border: none !important;
  background-color: #fff !important;
  padding: 0!important;
}
div.react-datepicker__week,.react-datepicker__day-names {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0!important;
  gap: 15px;
  @media screen and (max-width: 767.9px) {
    gap: 10px;
  }
}
div.react-datepicker__current-month,
div.react-datepicker-time__header,
div.react-datepicker-year-header {
  margin-bottom: 20px !important;
  //line-height: 40px;
  font-family:$font-family-Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

div.react-datepicker {
  //border-radius: 8.6px;
 // width: 471px;
 // box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12)!important;
 // border: solid 1.5px #e8e8e8;
 // padding: 23.4px 47px;
 border-style: none;
  .react-datepicker__month-container{
  float: none;
  
    @media screen and (max-width: 767.9px) {
      display: flex;
    align-items: center;
    flex-direction: column;
     }
  }
  @media screen and (max-width: 767.9px) {
    width: 100%;
   }
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon {
  border-color: #000000 !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #000000 !important;
}
div.react-datepicker__day--disabled, 
div.react-datepicker__month-text--disabled, 
div.react-datepicker__quarter-text--disabled, 
div.react-datepicker__year-text--disabledu{
  color: #cccccc!important;
}
div.react-datepicker__day-name{
  font-family:$font-family-InterBold;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
   color: #646464;
  width: 32px;
  text-align: center;
  margin: 0;
  height: 32px;
  line-height: 32px;
  margin-right: 10px;
  margin-bottom: 10px;
  @media screen and (max-width: 767.9px) {
   // width:10vw;
    margin-right: 0;
  }
}
/*div.react-datepicker__day-name:first-child,div.react-datepicker__day:first-child{
  text-align: left;
}*/
div.react-datepicker__day-name:last-child,div.react-datepicker__day:last-child{
  margin-right: 0;
}
div.react-datepicker__month{
  margin: 0;

}
div.react-datepicker__day--selected{
  color: #000000!important;
}
div.react-datepicker__day{
  width: 32px;
 // text-align: left;
  margin: 0;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-family:$font-family-InterSemiBold;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  margin-right: 10px;
  margin-bottom: 10px;
  @media screen and (max-width: 767.9px) {
   // width: 10vw;
    margin-right:0;
  }
}
button.react-datepicker__navigation--previous {
  left: -2px;
  //top: 20px;
  @media screen and (max-width: 767.9px) {
    left: calc(50% - 145px);
  }
}
button.react-datepicker__navigation--next {
right: -2px;
 // top: 20px;
  @media screen and (max-width: 767.9px) {
    left: calc(50% - -113px);
  }
}
.react-datepicker-popper[data-placement^=bottom] {
  @media screen and (max-width: 767.9px) {
  width: calc(100% - 40px);
  margin: auto;
  }
}
#onetrust-consent-sdk{
  @media only screen and (max-width: 425px){
    #onetrust-banner-sdk.otFloatingRoundedCorner {
      width: calc(100% - 32px);
      max-width: 100%;
      left: 1em;
      bottom: 2rem;
    }
  }
  @media only screen and (max-width: 600px){
    #onetrust-banner-sdk.otFloatingRoundedCorner {
      width: calc(100% - 32px);
      max-width: 100%;
      left: 1em;
      bottom: 2rem;
    }
  }
}
.Toastify__toast-container{
  padding: 0;
  @media screen and (min-width:768px) {
    left: calc(50% - 150px); // left: 0px;
   }
   @media screen and (max-width:767.9px) {
    left: calc(50% - 0px); // left: 0px;
    transform: translateX(-50%);
    width: calc(100vw - 32px);
   }
   .Toastify__toast-body > div:last-child{
    width: calc(100% - 42px);
    padding-left: 70px;
   }
  .Toastify__toast-body{
    padding: 0;
    display: block;
  }
  .Toastify__toast {
    display: inline-block;
    width: 600px;
  padding: 16px 20px 16px 32px;
  position: relative;
  font-family:$font-family-Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  @media screen and (max-width:767.9px) {
     width: 100%;
}
  }
  :root {
    --toastify-color-light: #fff;
    --toastify-text-color-light: #000;
    --toastify-toast-min-height: 46px;
    --toastify-toast-width: 520px;
    /* --toastify-font-family:  */
  }
  .Toastify__close-button {
    margin: auto;
  }
  .Toastify__toast-icon {
    margin-right: 24px;
    width: 49px;
    height: 49px;
    float:left ;
  }
  .tostCloseIcon {
position: absolute;
right: 20px;
top: 16px;
  }
  .toastWarning {
    border-radius: 50%;
  }
}

.uni-login-address-input-placeholder>#serviceAddress-label{
  margin-top: 11px;
  margin-left: 11px;
  color: rgba(0, 0, 0, 0.6);
}

.planOptionsCancelBtn {
  margin-left: 10px !important;
}
.voicePlanBtn {
  margin: 0.8rem !important;
}
.shopExprMain{
  overflow-y: hidden;
}

@media print {
  .noPrint {
    display: none !important;
  }
}

body > iframe#webpack-dev-server-client-overlay{
  display: none;
}